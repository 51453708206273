import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/home';
import Products from './pages/products/products';
import Detail from './pages/detail';
import NotFound from './pages/NotFound';
import Login from './pages/login';
import Register from './pages/register';
import ForgotPassword from './pages/forgot-password';
import Category from './pages/category';
import Orders from './pages/orders/orders';
import Payment from './pages/payment';
import Receipt from './pages/finance/receipt';
import OrdersPdf from './pages/orders-pdf';
import Measure from './pages/measure';
import Customer from './pages/customer';
import Users from './pages/user/users';
import Configurations from './pages/configuration';
import Pofile from './pages/pofile/index';
import Company from './pages/company/index';
import Inventory from './pages/inventory/index';
import ItemJournal from './pages/inventory/journal';
import ReturnReceive from './pages/inventory/return/index';
import ReceiveAdjust from './pages/inventory/adjust/mode';
import ApprovalSetup from './pages/approval';
import SaleReport from './pages/orders/sale-report';
import BillingNote from './pages/finance/billing-note';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/list" element={<Products />} />        
        <Route path="/product/:id/:name" element={<Detail/>}  /> 
        <Route path="/login" element={<Login />} />     
        <Route path="/register" element={<Register />} /> 
        <Route path="/category/list" element={<Category />} />      
        <Route path="/orders" element={<Orders />} />   
        <Route path="/orders/pdf" element={<OrdersPdf />} />   
        <Route path="/payment" element={<Payment />} />  
        <Route path="/measure" element={<Measure />} />  
        <Route path="/customer/list" element={<Customer />} />  
        <Route path="/user/list" element={<Users />} />  
        <Route path="/config" element={<Configurations />} />  
        <Route path="/company/list" element={<Company />} />          
        <Route path="/forgot-password" element={<ForgotPassword />} /> 
        <Route path="/approve/setup" element={<ApprovalSetup />} /> 

        <Route path="/inventory" element={<Inventory />} />        
        <Route path="/inventory/journal" element={<ItemJournal />} />    
        <Route path="/inventory/return" element={<ReturnReceive />} />    
        <Route path="/inventory/adjust/:mode" element={<ReceiveAdjust />} />      
            
        <Route path="/pofile" element={<Pofile />} /> 
        <Route path="/sale/report" element={<SaleReport />} /> 

        <Route path="/finance/billing-note" element={<BillingNote />} /> 
        <Route path="/finance/receipt" element={<Receipt />} />

        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  );
}

export default App;
