import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import apiCompany from '../../api/company';
import apiUser from '../../api/user';
import OrderHeader from '../../component/orders/order-header';
import _global from '../../api/global';
import uuid from 'react-uuid';
import OrderEditable from '../../component/orders/order-editable';
import _ from 'lodash';

import {
    faTrashAlt,
    faPowerOff,
    faEdit,
    faFloppyDisk,
    faFileAlt,
    faPenAlt,
    faPrint,
    faPlusCircle,
    faEye,
    faEnvelope,
    faSearch
} from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Signature from '../../component/common/signature';
import FileUpload from '../../component/common/fileUpload';
import SendApprove from '../../component/common/send-approve';
import PrintCostSheet from '../../pages/orders/print-cost-sheet';

function Table({
    data,
    deleteProduct,
    setOrdersList,
    OrdersApi,
    ProductApi,
    DatePicker,
    unitOfmeasure,
    search,
    setSearch,
    setDateRange,
    startDate,
    endDate,
    Controller
}) {
    const { t } = useTranslation();
    const [filter, setFilter] = useState({});
    const [showModalOrder, setshowModalOrder] = useState('');
    const [productId, setProductId] = useState('');
    const [orders, setOrders] = useState([]);
    const [line, setLine] = useState([]);
    const [sumCost, setSumCost] = useState(0);
    const [profit, setProfit] = useState(0);
    const [totalsData, setTotalsData] = useState({});
    const [lineTotal, setLineTotal] = useState(0);
    const [dataModal, setDataModal] = useState({header: {}, line: []});
    const [newLine, setNewLine] = useState({ name: t('text'), date_amount: 1, price: 0 });
    const [enableEdit, setEnableEdit] = useState('d-block');

    const [show, setShow] = useState(false);
    const [addrow, setAddrow] = useState(false);
    const [showSearchItem, setShowSearchItem] = useState(false);
    const [searchResult, setSearchResult] = useState({});
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [afterSearch, setAfterSearch] = useState({});
    const [dataColumn, setDataColumn] = useState([]);
    const [printTemplate, setPrintTemplate] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setOrders(data)
        setshowModalOrder('show');
        Controller = {
            ...Controller,
            dataModal: dataModal,
            setDataModal: setDataModal,
            updateForm: () => { },
            setshowModalOrder: setshowModalOrder
        }


    }, [data, dataModal])

    const NumberFormat = _global.NumberFormat;

    const Totals = async (lines, header) => {
        let totalData = await _global.Totals(lines, header?.discount);
        setTotalsData(totalData);
        console.log('totalsData::', totalsData)
        let { lineAmount, totalCost, profitTotal } = totalData;
        setLineTotal(lineAmount);
        //setVat(vat);
        //setTotal(totals);
        setSumCost(totalCost);
        //console.log('profitTotal::', profitTotal)
        setProfit(profitTotal);
    }

    const btnEdit = () => {
        setEnableEdit('d-none');
        Controller.setViewData(false);
    }


    /**
     * Edit Order
     */
    const modalShow = async (e, header, actions = 'views') => {
        console.warn('<< open modal header >>>', header);
        _global.getUserFromToken();
        let orderLine = [];
        if (header?.id && header.id > 0) {
            /**
             * Modify Document
             */
           
            Controller.setForm({
                ..._.clone(header),
                attach: typeof header.attach !== 'object' ? JSON.parse(header.attach) : header.attach,
                items: typeof header.items !== 'object' ? JSON.parse(header.items) : header.items,
                no: header.document_no
            })

            orderLine = await OrdersApi.getOrderLine(header.id)
            if (actions === 'edit') {
                setEnableEdit('d-none');
                Controller.setViewData(false);
            } else {
                setEnableEdit('d-block')
                Controller.setViewData(true);
            }
        } else {
            /**
             * Create New Document
             */
            //header = await OrdersApi.createOrder();
            let last = await OrdersApi.getOrderId();
            let info = _global.getToken();
            header = {
                id: 0,
                comp: info.comp,
                user: info.id,
                "document_no": 'RTK-' + moment().format('YYMMDD') + last,
                "order_date": moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                "items": "",
                "cost": 0,
                "amount": 0,
                "total_amount": 0,
                "discount": 0,
                "total_discount": 0,
                "cust_name": "ทั่วไป",
                "cust_cid": "",
                "cust_mobile": "",
                "cust_project": "",
                "cust_place": "",
                "start_date": moment().startOf('month').format('YYYY-MM-DDT08:00:00.000Z'),
                "end_date": moment().endOf('month').format('YYYY-MM-DDT18:00:00.000Z'),
                "gps": "",
                "ref1": "",
                "ref2": "",
                "ref3": "",
                "status": 0,
                "deposit": 0,
                "attach": "",
                "owner": info.id
            }
            setEnableEdit('d-none'); // <-- Enable Edit
            Controller.setViewData(false);
            //console.log('after create order <<<', header);
        }

        //console.log('order line uuid ', orderLine)
        setLine(orderLine);

        /**
         * Set Company
         */
        let resCompany = await apiCompany.get(header?.comp || 1);
        let comp = {
            ...resCompany,
            data: typeof resCompany.data === 'string' ? JSON.parse(resCompany.data) : resCompany.data
        };

        /**
         * Set User
         */
        let getUser = await apiUser.get(header?.user || 1);
        let usr = {
            ...getUser,
            settings: typeof getUser.settings === 'string' ? JSON.parse(getUser.settings) : getUser.settings
        };

        var now = moment(header.start_date); //todays date
        var end = moment(header.end_date); // another date

        let params = {
            //header: { ..._.cloneDeep(header), ...Controller.form },
            header: _.cloneDeep(header),
            line: orderLine,
            user: usr,
            company: comp,
            countLine: orderLine.length,
            countDate: end.diff(now, 'days'),
            countHours: end.diff(now, 'hours'),
            start_date: new Date(header.start_date)
        }

        setDataModal(params);
        setAfterSearch(header.cust_name) // set default search value
        Totals(orderLine, header);

    }


    const setDeleteItem = (e, item) => setProductId(item.id);

    const comfirm = () => {
        deleteProduct(productId);
    }

    const statusRender = _global.JobStatus;

    const changeObject = async (event, value = null) => {
        const element = event.target.parentElement;
        let { id } = element.closest("tr");
        //console.log('event.target.values::', id, element, event.target.name)

        const newState = dataModal.line.map(obj => {
            if (obj.uuid === id) {
                return {
                    ...obj,
                    [event.target.name]: event.target.value,
                    ...event.target.name === 'time_unit' && { price: parseFloat(value) },
                    ...event.target.name === 'unit' && { base_unit: parseInt(event.target.id) }
                };
            }
            return obj;
        });
        //console.log('newstate::', newState);
        setDataModal({
            header: dataModal.header,
            line: newState
        });
        Totals(newState, dataModal.header);

    }

    const handleEnter = (event, nextInput) => {
        console.log('event.key""', event.key)
        if (nextInput !== 'qty') {
            if (!/[0-9-.]/.test(event.key) && ![
                'backspace',
                'tab',
                'delete',
                'arrowleft',
                'arrowright',
                'arrowup',
                'arrowdown',
                'end',
                'home'
            ].includes(event.key.toLowerCase())
            ) {
                event.preventDefault();
            }
        }

        if (event.key.toLowerCase() === "enter") {
            if (nextInput === 'total') {
                addNewLine(event);
            } else {
                const element = event.target.parentElement;
                const nextfield = element.closest("tr").querySelector(`input[name=${nextInput}]`);
                nextfield.focus();
            }
        }
        setEnableEdit('d-none')
        //event.preventDefault();          
    }

    const enterNextLine = (event, nextInput) => {
        console.log('next input ', nextInput)
        if (nextInput !== 'date_amount') {
            if (!/[0-9-]/.test(event.key) && event.key.toLowerCase() !== 'backspace' && event.key.toLowerCase() !== 'delete') {
                event.preventDefault();
            }
        }


        if (event.key.toLowerCase() === "enter") {
            if (nextInput === 'total') {
                addNewLine(event);
            } else {
                const element = event.target.parentElement;
                //console.log('getAttribute::', element.closest('tr').getAttribute('id'))
                const nextfield = element.closest("tbody > tr").querySelector(`input[name=${nextInput}]`);
                nextfield.focus();
            }
        }
    }

    const addNewLine = (e) => {
        if (e.key === 'Enter') {
            //console.log('enter')
            pickItem(e, {
                id: 0,
                date_amount: newLine.date_amount,
                discount: "0.00",
                end_date: null,
                img: "",
                qty: 1,
                item: 0,
                name: newLine.name,
                orders: dataModal.header.id,
                cost: newLine.cost,
                price: newLine.price,
                setting: "",
                unit: newLine.unit
            });
            document.querySelector(`input[name=name]`).focus();
        }
    }

    const removeNewLine = (event, line) => {
        //console.log('line need delete::', line);
        //console.log('before delete dataModal::', dataModal);
        let afterDelete = dataModal.line.filter((el) => el.uuid !== line.uuid);
        //console.log('after delete::', afterDelete);
        setDataModal({
            header: dataModal.header,
            line: afterDelete
        });
        Totals(afterDelete, dataModal.header);
        setLine(afterDelete);
    }

    const updateOrderList = () => {
        let orders = [];
        data.forEach(el => {
            if (parseInt(el.id) === parseInt(dataModal.header.id)) {
                orders.push({
                    ...dataModal.header,
                    items: JSON.stringify(dataModal.line)
                })
            } else {
                orders.push(el);
            }

        })

        let index = _.findIndex(orders, { id: dataModal.header.id });
        // Replace item at index using native splice
        orders.splice(index, 1, { ...dataModal.header });
        //console.log('update order table >>>', orders);
        setOrdersList(orders)
    }

    const saveBtn = async (status = 1) => {



        let param = {
            header: {
                ...dataModal.header,
                status: status,
                amount: lineTotal,
                owner: Controller.form?.owner,
                items: JSON.stringify(dataModal.line),
                attach: JSON.stringify(Controller.form?.attach || []),
                uuid: uuid() // change for unlink approve
            },
            line: dataModal.line
        }
        console.warn('>>> update Order::', param);
        if (dataModal.header.id === 0) {
            // create
            let header = await OrdersApi.createOrder();
            param.header.id = header.id;
            setDateRange('')
            data?.push(header);
        }

        // let newData = param.line.filter(el => el.id === 0);
        // console.log('insert new line::', newData);

        let res = await OrdersApi.updateOrder(param);
        //console.log('<<< update order::', res);

        setDataModal({
            ...res,
            header: {
                ...res.header,
                items: JSON.stringify(res.header.items),
                attach: JSON.stringify(res.header.attach)
            }
        });
        //console.log('after save >>>>>>>', res)
        Controller.setForm({
            ...res.header,
            no: res.header.document_no
        });


        let orders = [];
        data.forEach(el => {
            if (parseInt(el.id) === parseInt(param.header.id)) {
                orders.push({
                    ...el,
                    items: JSON.stringify(res.header.items),
                    attach: JSON.stringify(res.header.attach),
                    status: status
                })
            } else {
                orders.push(el);
            }

        })

        setOrdersList(orders) /**<---- Update data */
        setShow(false);

        setEnableEdit('d-block')
        Controller.setViewData(true);

    }

    const updateHeader = async (column, value) => {

        let updateDateModal = {
            ...dataModal,
            header: {
                ...dataModal.header,
                [column]: value
            }
        };

        var now = moment(updateDateModal.header.start_date); //todays date
        var end = moment(updateDateModal.header.end_date); // another date
        //console.log('diff d>>', end.diff(now, 'days'));
        //console.log('diff h>>', end.diff(now, 'hours'));

        updateDateModal.countDate = end.diff(now, 'days');
        updateDateModal.countHours = end.diff(now, 'hours');

        setDataModal(updateDateModal);
        Totals(updateDateModal.line, updateDateModal.header);

        let body = {
            [column]: value
        }
        let res = await OrdersApi.updateHeader(body, dataModal.header.id);

        let orders = [];
        data?.forEach(el => {
            if (parseInt(el.id) === parseInt(dataModal.header.id)) {
                orders.push({
                    ...el,
                    [column]: value
                })
            } else {
                orders.push(el);
            }

        })

        let index = _.findIndex(orders, { id: dataModal.header.id });
        // Replace item at index using native splice
        orders.splice(index, 1, { ...res.data });

        setOrdersList(orders)


    }

    const closeModal = () => {
        $('body').find('button.close').click();
        setEnableEdit('d-block');
        setShow(false);
        setDataModal([]);
        Controller.setForm({});
    }


    const searchByName = async (words) => {
        try {
            let { data } = await ProductApi.searchOrder(words);
            //console.log('response data::', data);
            setSearchResult(data);
        } catch (e) {
            console.error(e);
        }
    }

    const searchItem = async (event) => {
        if (event.key.toLowerCase() === "enter") {
            //console.log('event', event.target.value)
            searchByName(event.target.value);
        }
    }

    const pickItem = (event, item) => {
        let id = dataModal.header.id;
        let end_date = dataModal.header.end_date;
        let start_date = dataModal.header.start_date;
        /**
         * Add new Line
         */
        console.log('push item in new line', item);
console.warn('dataModal::', dataModal)
        dataModal.line?.push({
            id: 0,
            item_code: item.code,
            comp: 1,
            date_amount: item.date_amount || 1,
            discount: "0.00",
            end_date: end_date,
            img: item.img,
            item: item.id,
            name: item.name + item.description,
            qty: item.qty || 1,
            orders: id,
            cost: item.cost,
            price: item.price,
            prices: item.prices && JSON.parse(item.prices),
            setting: item.setting && JSON.parse(item.setting),
            time_unit: item.time_unit || (item.prices && Object.keys(JSON.parse(item.prices))[0]) || t('days'),
            start_date: start_date,
            unit: (item.unit && Object.keys(JSON.parse(item.unit))[0]) || t('unit'),
            base_unit: item.base_unit,
            units: (item.unit && JSON.parse(item.unit)) || { 'unit': t('unit') },
            user: 1,
            vat: "0.00",
            uuid: uuid()
        })
        console.log('line >>', line)
        setDataModal({
            header: dataModal.header,
            line: dataModal.line
        });

        Totals(dataModal.line, dataModal.header);



    }

    const dateFilter = (name, date) => {
        //console.log('>>', date)
        setFilter({
            ...filter,
            [name]: date
        })
    }

    const print = () => {
        setPrintTemplate(1);
        setTimeout(() => {
            window.print();
        }, 500)

    }

    const printCostSheet = () => {
        setPrintTemplate(2);
        setTimeout(() => {
            window.print();
        }, 500)

    }

    /**
     * Data Table 
     * https://react-data-table-component.netlify.app/?path=/docs/getting-started-intro--page
     * 
     * */

    const columns = [
        {
            name: <b>{t('Order id')}</b>,
            selector: row => row.document_no,
            sortable: true,
            id: "document_no",
            cell: (order) => (
                <div>
                    <div>{order.document_no}</div>
                    <div className='font-10'>
                        {order.cust_place}
                    </div>
                    <div className='font-10'>
                        {moment(order.order_date).format('YYYY-MM-DD')}
                    </div>
                </div>
            )
        },
        {
            name: <b>{t('customer name')}</b>,
            selector: row => row.cust_name,
            sortable: true,
            id: "cust_name"
        },
        {
            name: <b>{t('end date rent')}</b>,
            selector: row => moment(row.end_date).format('YYYY-MM-DD'),
            sortable: true,
            id: "end_date"
        },
        {
            name: <b>{t('sales amount')}</b>,
            selector: row => _global.NumberFormat(row.amount),
            sortable: true,
            id: "amount"
        },

        {
            name: <b>{t('status')}</b>,
            selector: row => row.status,
            sortable: true,
            id: "status",
            cell: (order) => <div className={`font-10 ${statusRender(order.status).class}`}>
                <p>{statusRender(order.status).text}</p>
            </div>
        },
        {
            name: <b>{t('Action')}</b>,
            selector: row => row.id,
            id: "action",
            width: '220px',
            cell: (order) => <div className='text-right font-10'>
                <button className="btn btn-primary btn-xs" onClick={(e) => modalShow(e, order)} data-toggle="modal" data-target="#addModalOrder"><FontAwesomeIcon icon={faEye} /> {t('view')} </button>
                <button className="btn btn-warning btn-xs ml-1" onClick={(e) => modalShow(e, order, 'edit')} data-toggle="modal" data-target="#addModalOrder"><FontAwesomeIcon icon={faEdit} /> {t('edit')} </button>
                <button className="btn btn-danger btn-xs ml-1" onClick={(e) => setDeleteItem(e, order)} data-toggle="modal" data-target="#modalConfirm"><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')} </button>
            </div >
        }

    ];


    const ExpandedComponent = ({ data: {
        id,
        img,
        alt,
        items
    } }) =>
        <div className='w-100' style={{ borderLeft: "1px solid #ccc", borderRight: "1px solid #ccc", backgroundColor: "#f5f5f5" }}>
            <div className="row">
                <div className="col-12 p-3">

                    {
                        items && JSON.parse(items).map((el, index) => (
                            <div key={index + 1} className={`row  font-12`}>
                                <div className='col-2 d-none'> {el.id} </div>
                                <div className='col-1'>{index + 1}</div>
                                <div className='col-7'> {el.name} </div>
                                <div className='col-2 text-right'> {el.qty} </div>
                                <div className='col-2'> {el.unit} </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
        ;

    const DataTableBase = (props) => {
        return (
            <DataTable

                pagination
                selectableRowsComponent={Checkbox}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                columns={columns}
                data={orders || []}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                sortFunction={_global.customSort}
                defaultSortFieldId="start_date"
                defaultSortAsc={false}
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />
        );
    }
 
    return (
        <div>
            <div className='col'>
                <div className='row'>
                    <div className='col'>
                        <h1 className="h3 mb-2 text-gray-800">{t('Sale cost sheet')}</h1>
                    </div>
                    <div className='col text-right'>
                        <button
                            type="button"
                            className='btn btn-outline-success btn-sm'
                            onClick={(e) => modalShow(e, null)}
                            data-toggle="modal"
                            data-target="#addModalOrder"
                        >
                            <FontAwesomeIcon icon={faPlusCircle} /> {t('Add')}
                        </button>
                    </div>
                </div>
            </div>


            <div className={`modal fade modal-fullscreen ${showModalOrder}`} id="addModalOrder" data-backdrop="static" data-keyboard="false" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-none text-white bg-primary disabled-print">
                            <h5 className="modal-title modal-add-title  ">
                                <FontAwesomeIcon icon={faFileAlt} /> {t('Order detail')}
                            </h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body modalNew-add-detail ">
                            <div className={`row d-none ${printTemplate === 1 ? 'print-cost-sheet' : ''}`}>
                                <PrintCostSheet />
                            </div>
                            <div className={`row ${printTemplate === 2 ? 'print-quotation' : ''}`}>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col">
                                            <OrderHeader
                                                dataModal={dataModal}
                                                updateHeader={updateHeader}
                                                enableEdit={enableEdit}
                                                setDataModal={setDataModal}
                                                setShowSearchResult={setShowSearchResult}
                                                showSearchResult={showSearchResult}
                                                afterSearch={afterSearch}
                                                setAfterSearch={setAfterSearch}
                                                setOrdersList={setOrdersList}
                                                ordersList={data}
                                            />
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col  font-13">
                                            <OrderEditable
                                                dataModal={dataModal}
                                                updateHeader={updateHeader}
                                                enableEdit={enableEdit}
                                                changeObject={changeObject}
                                                handleEnter={handleEnter}
                                                enterNextLine={enterNextLine}
                                                removeNewLine={removeNewLine}
                                                newLine={newLine}
                                                setNewLine={setNewLine}
                                                addrow={addrow}
                                                setAddrow={setAddrow}
                                                showSearchItem={showSearchItem}
                                                setShowSearchItem={setShowSearchItem}
                                                handleClose={handleClose}
                                                handleShow={handleShow}
                                                show={show}
                                                setShow={setShow}
                                                searchItem={searchItem}
                                                searchResult={searchResult}
                                                pickItem={pickItem}
                                                searchByName={searchByName}
                                                unitOfmeasure={unitOfmeasure}
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-between font-13">
                                        <div className='col-12 col-sm-8 col-md-7'>
                                            <textarea
                                                className="form-control d-print-noline font-12 mb-2"
                                                id="remark"
                                                rows="3"
                                                readOnly={enableEdit === 'd-block' ? true : false}
                                                value={dataModal.header?.remark || ''}
                                                onChange={el => updateHeader('remark', el.target.value)}
                                                placeholder={t('remark')}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-8 col-md-5">
                                            <table className='table table-bordered border-red'>
                                                <tbody>
                                                    <tr>
                                                        <td className='text-right'>
                                                            ยอดขายรวม (บาท)
                                                        </td>
                                                        <td className='text-right' style={{ minWidth: 130 }}>
                                                            {NumberFormat(lineTotal)}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className='text-right'>
                                                            ต้นทุนรวม (บาท)
                                                        </td>
                                                        <td className='text-right' style={{ minWidth: 130 }}>
                                                            {NumberFormat(sumCost)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right'>
                                                            ส่วนลดพิเศษ (บาท)
                                                        </td>
                                                        <td className='text-right' style={{ paddingRight: 0 }}>
                                                            <input
                                                                type="text"
                                                                name="discount"
                                                                value={parseFloat(dataModal.header?.discount || 0, enableEdit === 'd-block' ? 2 : 0)}
                                                                className="form-control text-right d-print-noline font-13"
                                                                onChange={el => updateHeader('discount', el.target.value)}
                                                                disabled={enableEdit === 'd-block' ? true : false}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right'>
                                                            กำไร (บาท)
                                                        </td>
                                                        <td className='text-right'>
                                                            {NumberFormat(profit)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-right'>
                                                            {`${t('rental profit %')}`}
                                                        </td>
                                                        <td className='text-right'>
                                                            {NumberFormat((totalsData.profitSales || 0).toFixed(2))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <Signature Controller={Controller} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col disabled-print">
                                            <FileUpload.FormUpload controller={Controller} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 disabled-print d-none">
                                    <div className="row">
                                        <div className="col"></div>
                                        <div className="col-5">
                                            <div className="card">
                                                <div className="card-header bg-secondary text-white">
                                                    {t('Payment')}
                                                </div>
                                                <div className="card-body">

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer bg-none disabled-print">
                            <SendApprove Controller={Controller} />
                            {/* <button className={`btn btn-primary btn-sm  ${enableEdit} align-self-left`} type="button" onClick={printCostSheet} ><FontAwesomeIcon icon={faPrint} /> {t('print cost sheet')}</button> */}
                            <button className={`btn btn-info btn-sm  ${enableEdit} align-self-left`} type="button" onClick={print} ><FontAwesomeIcon icon={faPrint} /> {t('print')}</button>

                            {/* <button className={`btn btn-primary btn-sm ${enableEdit === 'd-none' ? `d-block` : `d-none`}`} type="button" onClick={el => saveBtn(2)} data-dismiss="modal" ><FontAwesomeIcon icon={faCheckCircle} /> {t('approve')}</button> */}
                            {/* <button className={`btn btn-primary btn-sm ${enableEdit === 'd-none' ? `d-block` : `d-none`}`} type="button" onClick={el => saveBtn(2)} ><FontAwesomeIcon icon={faCheckCircle} /> {t('save & approve')}</button> */}
                            <button className={`btn btn-primary btn-sm ml-2 ${enableEdit}`} onClick={() => Controller.setConfirmApprove(true)}> <FontAwesomeIcon icon={faEnvelope} /> {t('send approve')} </button>
                            <button className={`btn btn-success btn-sm ${enableEdit === 'd-none' ? `d-block` : `d-none`}`} type="button" onClick={el => saveBtn(1)} ><FontAwesomeIcon icon={faFloppyDisk} /> {t('save')}</button>
                            <button className={`btn btn-warning btn-sm ${enableEdit}`} type="button" onClick={el => btnEdit('d-none')} ><FontAwesomeIcon icon={faPenAlt} /> {t('edit')}</button>
                            <button className="btn btn-secondary btn-sm" type="button" onClick={closeModal}><FontAwesomeIcon icon={faPowerOff} /> {t('close')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalConfirm" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-danger text-white">
                            <h5 className="modal-title">Confirm</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{t('comfirn delete')}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={comfirm} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col">
                            <h6 className="m-0 font-weight-bold text-primary">{t('Orders')}</h6>
                        </div>
                        <div className="col text-right">
                            <div className="row d-none">
                                <div className="col text-right" style={{ margin: '0px' }}>
                                    <DatePicker
                                        selected={filter.start_date || new Date()}
                                        onChange={(date) => dateFilter('start_date', date)}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control  "
                                        placeholder={t('start date')}
                                        aria-label={t('form date')}
                                    />
                                </div>
                                <div className="col-2 padding-0" style={{ margin: '0px' }}><span className="input-group-text">{t('to')}</span></div>
                                <div className="col padding-0" style={{ margin: '0px' }}>
                                    <DatePicker
                                        selected={filter.end_date || new Date()}
                                        onChange={(date) => dateFilter('end_date', date)}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        placeholder={t('end date')}
                                        aria-label={t('end date')}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="">
                        <div className="row">
                            <div className="col-12 col-sm-6"></div>
                            <div className="col-12 col-sm-6  mb-3">
                                <div className='w-100 d-flex justify-content-between'>
                                    <DatePicker
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}
                                        className="form-control"
                                        placeholderText={t('date filter')}
                                        dateFormat="yyyy-MM-dd"
                                        withPortal
                                    />
                                    <div className="input-group mb-3 ml-2" style={{ maxWidth: 320 }}>
                                        <input type="text" className="form-control"
                                            placeholder={t('search')}
                                            aria-label={t('search')}
                                            aria-describedby="basic-addon2"
                                            value={search || ''}
                                            onChange={(el) => setSearch(el.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary"
                                                type="button"
                                            >
                                                <FontAwesomeIcon icon={faSearch} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-12 col-sm-4'>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder={t('search')} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button">
                                            <FontAwesomeIcon icon={faSearch} /> <span className='ml-1'> </span>
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <DataTableBase />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Table;

