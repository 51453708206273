import React from 'react';
import Template from '../../component/common/template';

import { useTranslation } from 'react-i18next';

const Inventory = (props) => {
    const { t } = useTranslation();
    
    return (
        <Template>
             {t('Pofile')}
 
        </Template>
    );
    
}

export default Inventory;
