import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import CustomerApi from '../../api/customer';
import UserApi from '../../api/user';
import CompanyApi from '../../api/company';
import Template from '../../component/common/template';
import CustomerTable from '../../component/customer-table';
import ModalNew from '../../component/modalNew';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit, faPlusCircle, faPowerOff, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import _global from '../../api/global';
import Waiting from '../../component/waiting';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';




const Users = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [deleteItem, setDeleteItem] = useState(0);
    const [editable, setEditable] = useState(false);
    const [company, setCompany] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                let response = await UserApi.getData();
                setData(response);

                let { data: companyList } = await CompanyApi.search('');
                console.log('companyList::', companyList)

                if (_global.getToken().comp === 1) {
                    setCompany(companyList);
                } else {
                    let companies = companyList.filter((el) => el.id === _global.getToken().comp)
                    setCompany(companies);
                }


            } catch (e) {
                console.error('error get customer::', e)
            }

        }
        fetchData().catch(console.error);


    }, [])

    const submitForm = async (e) => {
        e.preventDefault();
        //console.log('submit data::', formData);
        try {
            setLoading(true);
            if (!isNaN(formData.id) && !formData.interrupt) {
                // update to db
                //console.log('do update')
                let body = _.omit(formData, 'id');
                let res = await UserApi.updateData(body, formData.id);
                //console.log('res updateData::', res);
                if (res.data.status === 2) {

                    let index = _.findIndex(data, { id: formData.id });
                    // Replace item at index using native splice
                    data.splice(index, 1, { ...res.data, status: formData.status });

                    setData(data);

                    //console.log('updateed data ', data);

                    setTimeout(() => {
                        setLoading(false);
                        $('body').find('button.close').click();
                    }, 500)
                    return res.data;
                }

            } else {
                // create new user
                //console.log('do create', formData)
                let body = _.omit(formData, 'id');
                let res = await UserApi.register({ ...body, password: formData.passwd });
                //console.log('res create user::', res);
                if (res.status === 2) {
                    let newData = data.concat(res);
                    newData.status = body.status;
                    setData(newData);

                    //console.log('create new datatable ', newData);

                    setTimeout(() => {
                        setLoading(false);
                        $('body').find('button.close').click();
                    }, 500)
                    return res.data;
                }
            }

            setLoading(false);
        } catch (e) {
            console.error('error::', e);
            setLoading(false);
        }
    };

    const newDoc = () => {
        setEditable(true)
        setFormData({
            email: '',
            f_name_th: 'name',
            l_name_th: 'lastname',
            phone: '0888888888',
            username: 'aa',
            status: 1,
            comp: _global.getToken().comp
        })
    }

    const closeModal = () => {
        /**
         * Clear data after close modal
         */


        document.getElementById("close-addModalNew").click();
    }

    const editDoc = (e, item) => {
        setEditable(false)
        setFormData(_.omit(item, 'password'))
    }

    const ModalConfirmDelete = () => {
        const comfirmDelete = async () => {
            let id = deleteItem;
            let cachedData = _.cloneDeep(data);
            let newData = data.filter((e) => e.id !== id);
            setData(newData);
            let res = await UserApi.remove(id)
            if (res.data.status != 2) {
                setData(cachedData);
                alert(res.data.message);
            }
        }
        return (
            <div>
                <div className="modal fade" id="modalConfirm" tabIndex={-1} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-danger text-white">
                                <h5 className="modal-title">Confirm</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>{t('comfirn delete')}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={comfirmDelete} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    /**
     * Data Table 
     * https://react-data-table-component.netlify.app/?path=/docs/getting-started-intro--page
     * 
     * */
    const Title = () => {
        return <>
            <div className='row'>
                <div className='col'>
                    <h3 className=" mb-2 text-gray-800">{t('Users info')}</h3>
                </div>
                <div className='col text-right'>
                    <button
                        type="button"
                        className='btn btn-outline-success btn-sm'
                        onClick={() => newDoc()}
                        data-toggle="modal"
                        data-target="#addModalNew"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} /> {t('Add')}
                    </button>
                </div>
            </div>

        </>
    }

    const columns = [
        {
            name: t('username'),
            selector: row => row.username,
            sortable: true,
            id: "username"
        },
        {
            name: t('email'),
            selector: row => row.email,
            sortable: true,
            id: "email"
        },
        {
            name: t('name'),
            selector: row => row.f_name_th,
            sortable: true,
            id: "f_name_th"
        },
        {
            name: t('last name'),
            selector: row => row.l_name_th,
            sortable: true,
            id: "l_name_th"
        },
        {
            name: t('phone'),
            selector: row => row.phone,
            sortable: true,
            id: "phone"
        },
        {
            name: t('department'),
            selector: row => row.position,
            sortable: true,
            id: "department"
        },
        {
            name: t('status'),
            selector: row => row.status,
            sortable: true,
            id: "status",
            cell: (item) => <div className={`font-10 ${_global.UserStatus(item.status).class} `}>{_global.UserStatus(item.status).text}</div>,
            maxWidth: '50px'
        },
        {
            name: t('Action'),
            selector: row => row.id,
            id: "action",
            width: '150px',
            cell: (item) => <div className='w-100 text-right'>
                <button className="btn btn-warning btn-xs ml-1" onClick={(e) => editDoc(e, item)} data-toggle="modal" data-target="#addModalNew"><FontAwesomeIcon icon={faEdit} /> {t('edit')} </button>
                <button className="btn btn-danger btn-xs ml-1" onClick={(e) => setDeleteItem(item.id)} data-toggle="modal" data-target="#modalConfirm"><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')} </button>
            </div >
        }

    ];

    const DataTableBase = (props) => {
        return (
            <DataTable
                title={<Title />}
                pagination
                selectableRowsComponent={Checkbox}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                columns={columns}
                data={data || []}
                sortFunction={_global.customSort}
                defaultSortFieldId="code"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />
        );
    }

    return (
        <Template >
            <Waiting loading={loading} />
            <ModalConfirmDelete />
            <ModalNew
                submitForm={submitForm}
                ModalName={t('Customers')}
                closeModal={closeModal}
                mode={'modal-fullscreen'}
                bg={'bg-primary'}
            >
                <UserApi.FormAddData
                    data={data}
                    setData={setData}
                    setLoading={setLoading}
                    formData={formData}
                    setFormData={setFormData}
                    company={company}
                    t={t}
                    setEditable={setEditable}
                    editable={editable}
                />
            </ModalNew>
            <DataTableBase />
        </Template>
    );
}

export default Users;
