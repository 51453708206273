import React, { useEffect, useState } from 'react';
import _global from '../../api/global';
import Template from '../../component/common/template';
import Waiting from '../../component/waiting';
import JournalApi from '../../api/journal';
import InventoryApi from '../../api/inventory';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {
    faBan,
    faCheck,
    faEdit, faEye, faFloppyDisk, faPlusCircle, faPowerOff, faTimes, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment/moment';

const Inventory = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();

    }, []);

    const getData = async () => {
        setLoading(true);

        setTimeout(async () => {
            let date = new Date();
            var from = new Date(date.getFullYear(), date.getMonth(), 1);
            var to = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate() + 1);

            let { data: res } = await InventoryApi.search(from, to, null);


            let sort = [...res].sort((a, b) => {
                var c = new Date(a.datetime);
                var d = new Date(b.datetime);
                return d - c;
            });

            setData(sort);
            setLoading(false);
        }, 300);
    }

    const Title = ({ controller }) => {
        return <>
            <div className='row'>
                <div className='col'>
                    <h3 className=" mb-2 text-gray-800">{t('inventory journal')}</h3>
                </div>
                <div className='col text-right'>

                </div>
            </div>

        </>
    }
    const controller = ({

    })

    const ExpandedComponent = ({ data }) => {

        let payload = _.clone(data.payload);
        delete payload?.payload.positions;
        delete payload?.payload?.items;
        return <div className='w-100 font-11 d-flex justify-content-between' style={{ borderLeft: "1px solid #ccc", borderRight: "1px solid #ccc" }}>
            <div className='m-2'>
                <div>{payload?.source_des}</div>
            </div>
            <div style={{ backgroundColor: "#f5f5f5" }}>
                <pre className='font-9'>{JSON.stringify(payload, null, 2)}</pre>
            </div>
        </div>
    };

    return (
        <Template>
            <Waiting loading={loading} />
            <DataTable
                title={<Title controller={controller} />}
                noDataComponent={t('There are no records to display')}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                data={data || []}
                expandableRows
                //expandableRowExpanded={row => true}
                //expandableRowDisabled={row => false}
                expandableRowsComponent={ExpandedComponent}
                columns={[
                    {
                        name: t('document no'),
                        selector: row => row.payload?.payload?.no || '-',
                        sortable: true,
                        minWidth: 100,
                        maxWidth: 220,
                        id: "document_no"
                    },


                    {
                        name: t('item code'),
                        selector: row => row.code,
                        sortable: true,
                        id: "item_code"
                    },
                    {
                        name: t('product name'),
                        selector: row => row.name,
                        sortable: true,
                        id: "item_name"
                    },
                    {
                        name: <div className="w-100 text-right">{t('increase')}</div>,
                        selector: row => row.increase,
                        cell: (row) => <div className={`w-100 text-right ${parseFloat(row.increase) <= 0 ? 'text-danger' : ''}`}>{
                            parseFloat(row.increase) === 0
                                ? ''
                                : (parseFloat(row.increase) < 0 ? '' : parseFloat(row.increase))
                        }</div>,
                        sortable: true,
                        id: "increase"
                    },
                    {
                        name: <div className="w-100 text-right">{t('decrease')}</div>,
                        selector: row => row.decrease,
                        cell: (row) => <div className={`w-100 text-right ${parseFloat(row.decrease) <= 0 ? 'text-danger' : ''}`}>{
                            parseFloat(row.decrease) === 0
                                ? (parseFloat(row.increase) < 0 ? parseFloat(row.increase * -1) : '')
                                : parseFloat(row.decrease)
                        }</div>,
                        sortable: true,
                        id: "decrease"
                    },
                    // {
                    //     name: <div className="w-100 text-right">{t('qty')}</div>,
                    //     selector: row => (Number(row.decrease) || Number(row.increase)),
                    //     cell: (row) => <div className={`w-100 text-right ${parseFloat(row.decrease) || parseFloat(row.increase) <= 0 ? 'text-danger' : ''}`}>{parseFloat(row.decrease) || parseFloat(row.increase)}</div>,
                    //     sortable: true,

                    //     id: "item_qty"
                    // },
                    {
                        name: <div className='w-100 text-center'>{t('date')}</div>,
                        selector: row => moment(row.datetime).format('YYYY-MM-DD H:mm'),
                        cell: (row) => <div className={`w-100 text-center`}>{moment(row.datetime).format('YYYY-MM-DD H:mm')}</div>,

                        sortable: true,
                        minWidth: 100,
                        maxWidth: 120,
                        id: "create_date"
                    },
                ]}

                sortFunction={_global.customSort}
                defaultSortFieldId="code"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />
        </Template>
    );

}

export default Inventory;
