import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import _global from '../../api/global';


function PrintCostSheet() {
    const { t } = useTranslation();


    useEffect(() => {

    }, [])





    return (
        <div>
            <div className='col'>
                <div className='row'>
                    <div className='col'>
                        <h1 className="h3 mb-2 text-gray-800">{t('Sale cost sheet')}</h1>
                    </div>
                    <div className='col text-right'>
                        x
                    </div>
                </div>
            </div>


        </div>
    )
}

export default PrintCostSheet;

