import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import _global from './global';
import { Buffer } from 'buffer';
import _ from 'lodash';
import { Popup } from '../component/pupup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const { client } = require('./auth').default;



const search = async (value) => {
    try {
        let unit = await _global.client.get(`/v1/unit`, {
            params:
            {
                search: value
            }
        });

        //console.log('unit::', unit)

        // unit?.data.sort((a, b) => {
        //     return a.name - b.name;
        // });
        return unit;
    } catch (error) {
        console.error(error);
    }
}

const getData = async () => {
    try {
        let response = await client.get(`/v1/unit`);
        //console.log('response::', response.data)
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const remove = async (id) => {
    try {
        return await client.delete(`/v1/unit/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}


const newData = async (body) => {
    try {
        body.status = 1;
        return await client.post(`/v1/unit`, body);
    } catch (e) {
        console.error('error::', e);
    }
}

const updateData = async (body, id) => {
    try {
        body.status = 1;
        return await _global.client.patch(`/v1/unit/${id}`, body);
    } catch (e) {
        console.error('error::', e);
    }
}



const FormAddData = ({ data, setData, setLoading, formData, setFormData }) => {


    useEffect(() => {

    }, [data])



    return <div>

        <div className="row">
            <div className="col-12 col-sm-6  mt-4">
                <label htmlFor='product-price'>{t('Code')}:</label>
                <input className="form-control" value={formData.code || ''} id={formData.code} onChange={e => setFormData({ ...formData, code: e.target.value })} />
            </div>

            <div className="col-12 col-sm-6  mt-4">
                <label htmlFor='product-price'>{t('Name')}:</label>
                <input className="form-control" value={formData.name || ''} id={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })} />
            </div>

        </div>



    </div>
}


const MeasureApi = {
    getData,
    FormAddData,
    remove,
    newData,
    updateData,
    search
};

export default MeasureApi;