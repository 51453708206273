import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import _global from './global';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    faBan,
    faCheck,
    faEdit, faEye, faFloppyDisk, faMinusCircle, faPlusCircle, faPowerOff, faTimes, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment/moment';
import ApprovalApi from './approval';
import FileUpload from '../component/common/fileUpload';
import Btn from '../component/journal/journal-btn';
import Signature from '../component/common/signature';
import SearchItem from '../component/common/searchItems';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
const url = '/v1/finance/billing-note';

const search = async (type, sub_type, from, to) => {
    try {
        let response = await _global.client.get(url, {
            params:
            {
                type: type,
                sub_type: sub_type || 2,
                from: from,
                to: to
            }
        });

        let list = [];
        for (let row of response.data) {
            list.push({
                ...row,
                items: row.items ? JSON.parse(row.items) : [],
                attach: row.attach ? JSON.parse(row.attach) : [],
                options: row.options ? JSON.parse(row.options) : []
            })
        }
        //console.log('response::', list)
        return { data: list };
    } catch (error) {
        console.error(error);
    }
}

const getData = async (id) => {
    try {
        let response = await _global.client.get(`${url}/${id}`);
        //console.log('response::', response.data)
        return response;
    } catch (error) {
        console.error(error);
    }
}

const remove = async (id) => {
    try {
        return await _global.client.delete(`${url}/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}


const create = async (body) => {
    try {
        return await _global.client.post(`${url}`, body);
    } catch (e) {
        console.error('error::', e);
    }
}

const update = async (body, id) => {
    try {
        return await _global.client.patch(`${url}/${id}`, body);
    } catch (e) {
        console.error('error::', e);
    }
}


const TableList = (props) => {

    const [deleteRow, setDeleteRow] = useState({});
    const view = (row) => {
        edit(row)
        props.controller.setViewData(true)
    }
    const edit = (row) => {
        props.controller.setForm({}); // clear First
        props.controller.setViewData(false)
        props.controller.setEditable(true)
        props.controller.setForm(row)
    }

    const newDoc = () => {
        props.controller.setEditable(true)
        props.controller.setForm(props.controller.default)
    }

    const confirmDelete = () => {
        props.controller.remove(deleteRow)
    }

    const confirmSendApprove = () => {
        //props.controller.sendApprove()
    }


    const Title = ({ controller }) => {
        return <>
            <div className='row'>
                <div className='col'>
                    <h3 className=" mb-2 text-gray-800">{t('Billing Note')}</h3>
                </div>
                <div className='col text-right'>
                    <button
                        type="button"
                        className='btn btn-outline-success btn-sm'
                        onClick={() => newDoc()}
                        data-toggle="modal"
                        data-target="#addModalOrder"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} /> {t('Add')}
                    </button>
                </div>
            </div>

        </>
    }
    return <div>
        <FormAddData controller={props.controller} />
        <div className="modal fade" id="modalDeleteJournal" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-danger text-white">
                        <h5 className="modal-title">Confirm</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{t('comfirn delete')}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={() => confirmDelete()} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="modalConfirmSendApprove" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-info text-white">
                        <h5 className="modal-title">Confirm</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{t('comfirn delete')}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={() => confirmSendApprove()} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                    </div>
                </div>
            </div>
        </div>

        <div className={`${props.controller.editable && 'd-none'}`}>
            <DataTable
                title={<Title controller={props.controller} />}
                noDataComponent={t('There are no records to display')}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                data={props.data || []}
                columns={[
                    {
                        name: t('date'),
                        selector: row => moment(row.options?.start_date).format('YYYY-MM-DD'),
                        sortable: true,
                        minWidth: 100,
                        maxWidth: 120,
                        id: "create_date",
                        cell: (row) => <div className='w-100'>
                            <span className='text-primary'>{moment(row.options?.start_date).format('DD/MM/YY')}</span> -
                            <span className='text-warning'> {moment(row.options?.end_date).format('DD/MM/YY')}</span>
                        </div >
                    },
                    {
                        name: t('document no'),
                        selector: row => row.no,
                        sortable: true,
                        maxWidth: '150px',
                        id: "no"
                    },
                    {
                        name: t('document source'),
                        selector: row => row.source_no,
                        sortable: true,
                        maxWidth: '150px',
                        id: "source_no"
                    },
                    {
                        name: t('customer name'),
                        selector: row => row.cust_name,
                        sortable: true,
                        id: "cust_name"
                    },
                    
                    {
                        name: t('amount'),
                        selector: row => row.amount,
                        sortable: true,
                        maxWidth: '150px',
                        id: "amount"
                    },
                    {
                        name: t('status'),
                        selector: row => <div className={`${_global.JournalStatus(row.status).className}`}>{_global.JournalStatus(row.status).text}</div>,
                        sortable: true,
                        maxWidth: '150px',
                        id: "status"
                    },
                    {
                        name: t('Action'),
                        selector: row => row.id,
                        id: "action",
                        width: '220px',
                        cell: (row) => <div className='w-100 text-right'>
                            <Btn row={row} view={view} edit={edit} setDeleteRow={setDeleteRow} />
                        </div >
                    }

                ]}

                sortFunction={_global.customSort}
                defaultSortFieldId="code"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />
        </div>
    </div>;


}

const NumberFormat = _global.NumberFormat;

const FormAddData = ({ controller }) => {
    const [showModal, setShowModal] = useState(false);
    const [fadeHide, setFadeHide] = useState('');
    const [editGroup, setEditGroup] = useState([2, 3, 4]);
    const [searchResult, setSearchResult] = useState([]);
    const [sumTotal, setSumTotal] = useState({});

    let form = controller.form;
    //console.log('searchOrder::', controller);

    useEffect(() => {
        if (!form.source_no) {
            setShowModal(false)
        } else {
            setShowModal(false)
        }

        checkApproveStatus();

        getTotal();
    }, [form])

    const getTotal = async () => {
        let sum_total = await _global.Totals(form.items, form.discount, form.withholdingTax, form)
        //console.log('sum total >>', sum_total);
        setSumTotal(sum_total);
    }

    const checkApproveStatus = async () => {
        try {

            let name = 'journal';
            let id = form.id;
            let res = await ApprovalApi.getBySource(name, id)
            //console.log('res getBySource::', res);

            let newApproval = [];
            if (controller.approver.length) {
                for (let approve of controller.approver) {
                    let entry = res.data.filter(el => el.source_table === approve.source_table && el.user === approve.user && el.uuid === form.uuid);
                    newApproval.push({ ...approve, entry: entry[0] || {} })
                }
                //console.log('::::', newApproval);
                controller.setApprover(newApproval)
            }

        } catch (e) {
            console.error('e::', e);
        }
    }

    const deleteLine = (item) => {
        let newItems = form.items.filter(el => el.id !== item.id);
        controller.setForm({ ...form, ...{ items: newItems } })
    }

    const updateForm = (name, value) => {
        let newForm = {
            ...form,
            [name]: value
        }
        controller.setForm(newForm)
    }

    const updateLine = (name, event) => {
        console.log('name::', name, '=>', event.target.value);


        const element = event.target.parentElement;
        const tr = element.closest("tr");
        let id = tr.id;

        let newItem = [];
        for (let item of form.items) {
            if (item.uuid === id) {
                let value = name === 'base_unit' ? event.target.id : event.target.value;

                // Validate from sale line
                // if (name === 'qty') {
                //     console.log('event.target.value > item.qty', event.target.value, item.qty)
                //     if (event.target.value > item.qty) {
                //         value = item.qty;
                //     } else {
                //         value = event.target.value;
                //     }
                // }

                newItem.push({
                    ...item,
                    [name]: value
                })
            } else {
                newItem.push(item);
            }
        }
        console.log('new item::', newItem);

        controller.setForm({ ...form, items: newItem })
    }

    const btnSendApprove = () => {
        controller.sendApprove();
        controller.setConfirmApprove(false)
        console.log('send approve')
    }

    const ModalSearch = ({ children, ModalName }) => {

        return <>

            <div className={`absolute-card shadow ${showModal ? 'd-block' : 'd-none'}`} >
                <div className="card  shadow">
                    <div className="card-header bg-warning">
                        <button type="button" className="close" onClick={() => setShowModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body text-start">
                        <h5 className="card-title">{t('document source')}</h5>
                        <input
                            name="source_no"
                            type={'text'}
                            className="form-control form-control-sm d-print-noline"
                            placeholder={t('input sale order number.')}

                            value={form.source_no || ''}
                            onChange={(el) => controller.getSource(el.target.value)}
                        />

                    </div>
                </div>
            </div>

            {/* MOdal Confirm */}
            <div className={`absolute-card shadow ${controller.confirmApprove ? 'd-block' : 'd-none'}`} >
                <div className="card  shadow">
                    <div className="card-header bg-success">
                        <button type="button" className="close" onClick={() => controller.setConfirmApprove(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body text-left">
                        <h5 className="card-title">{t('Confirm')}</h5>
                        <div className='d-flex justify-content-between'>
                            <button className='btn btn-secondary btn-sm' onClick={() => controller.setConfirmApprove(false)}>
                                <FontAwesomeIcon icon={faTimes} /> {t('cancel')}
                            </button>
                            <button className='btn btn-primary btn-sm' onClick={() => btnSendApprove()} >
                                <FontAwesomeIcon icon={faCheck} />  {t('Confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`shadow search-order-result ${controller.searchOrder.length > 0 ? 'd-block' : 'd-none'} `} >
                <div className='d-flex w-100 justify-content-end'>
                    <div className='cursor-pointer text-center'
                        style={{ width: 30 }}
                        onClick={() => controller.setSearchOrder([])}>
                        x
                    </div>
                </div>
                <div style={{ padding: 10 }}>
                    {
                        controller.searchOrder.length > 0 && controller.searchOrder?.map((el, index) => (
                            <div className="row row-hover" key={index + 1} onClick={() => controller.clickSelectSource(el)}>
                                <div className="col-3 border border-secondary bg-dark text-white pb-2"><b>{el.document_no}</b></div>
                                <div className="col border border-secondary">
                                    <b>{moment(el.order_date).format('MM/DD/YYYY')}</b></div>
                                <div className="col border border-secondary">{el.cust_name}</div>
                                <div className={`col border border-secondary ${_global.JobStatus(el.status).class} text-center`}>{_global.JobStatus(el.status).text}</div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </>
    }

    return <>
        <div className={`${!controller.editable && 'd-none'} mb-5`} style={{
            border: '0px solid #ccc',
            padding: 0,
            background: "#fff",
            position: 'relative'
        }}>
            <ModalSearch />
            <SearchItem.modal data={searchResult} controller={controller} setData={setSearchResult} />
            <table className='table table-bordered' >
                <thead>
                    <tr>
                        <th className='text-center' colSpan={'6'}>
                            <img src="/assets/images/head_ta.jpg" className='w-100' />
                        </th>

                        <th colSpan={'2'} width="20%" className='text-center align-top'>
                            <h5>{t('Billing Note')}</h5>
                            <div className='font-13'>ใบแจ้งหนี้/ใบส่งของ</div>
                            <div className='font-11 text-danger mt-5'>
                                {
                                    controller.viewData
                                        ? <div> เช่าตั่งแต่ {moment(form.options?.start_date).format('DD/MM/YY')} -  {moment(form.options?.end_date).format('DD/MM/YY')}</div>
                                        : <div className='row'>
                                            <div className="col">เช่าตั่งแต่</div>
                                            <div className="col-4">
                                                <DatePicker
                                                    selected={form.options?.start_date ? new Date(form.options?.start_date) : new Date()}
                                                    dateFormat="dd/MM/yy"
                                                    className="form-control form-control-sm d-print-noline font-11"
                                                    readOnly={controller.viewData}
                                                    onChange={(date) => updateForm('options', { ...form.options, start_date: moment(date).toISOString() })}
                                                />
                                            </div>
                                            <div className="col-1">-</div>
                                            <div className="col-4">
                                                <DatePicker
                                                    selected={form.options?.end_date ? new Date(form.options?.end_date) : new Date()}
                                                    dateFormat="dd/MM/yy"
                                                    className="form-control form-control-sm d-print-noline font-11"
                                                    readOnly={controller.viewData}
                                                    onChange={(date) => updateForm('options', { ...form.options, end_date: moment(date).toISOString() })}
                                                />
                                            </div>
                                        </div>
                                }

                            </div>
                        </th>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td colSpan={'5'} rowSpan={'3'}>
                            <div className='mb-2 d-flex'>
                                <span style={{ minWidth: 80 }}>{t('customer name')}</span> :
                                <span className='text-primary w-100 ml-2' style={{ marginTop: -6 }}>
                                    <textarea
                                        className="form-control d-print-noline font-12"
                                        id="cust_name"
                                        rows="2"
                                        // readOnly={editGroup.includes(form.status) || controller.viewData}
                                        readOnly={controller.viewData}
                                        value={form.cust_name || ''}
                                        onChange={(el) => updateForm('cust_name', el.target.value)}
                                    />
                                </span>
                            </div>
                            <div className='mb-2 d-flex'>
                                <span style={{ minWidth: 80 }}>{t('tax id')}</span> :
                                <span className='text-primary w-100 ml-2' style={{ marginTop: -6 }}>
                                    <textarea
                                        className="form-control d-print-noline font-12"
                                        id="cust_tax_id"
                                        rows="1"
                                        // readOnly={editGroup.includes(form.status) || controller.viewData}
                                        readOnly={controller.viewData}
                                        value={form.cust_tax_id || ''}
                                        onChange={(el) => updateForm('cust_tax_id', el.target.value)}
                                    />
                                </span>
                            </div>
                            <div className='mb-2 d-flex'>
                                <span style={{ minWidth: 80 }}>{t('address')}</span> :
                                <span className='text-primary w-100 ml-2' style={{ marginTop: -6 }}>
                                    <textarea
                                        className="form-control d-print-noline font-12"
                                        id="cust_address"
                                        rows="3"
                                        // readOnly={editGroup.includes(form.status) || controller.viewData}
                                        readOnly={controller.viewData}
                                        value={form.cust_address || ''}
                                        onChange={(el) => updateForm('cust_address', el.target.value)}
                                    />
                                </span>
                            </div>
                            <div className='d-flex'>
                                <span style={{ minWidth: 80 }}>{t('Project')}</span> :
                                <span className='text-primary w-100 ml-2' style={{ marginTop: -6 }}>
                                    <input
                                        name="detail"
                                        type={'text'}
                                        className="form-control form-control-sm d-print-noline font-12"
                                        // readOnly={editGroup.includes(form.status) || controller.viewData}
                                        readOnly={controller.viewData}
                                        defaultValue={form.detail || ''}
                                        onChange={(el) => updateForm('detail', el.target.value)}
                                    />
                                </span>
                            </div>
                        </td>
                        <td width="10%" style={{ minWidth: 120 }}>{t('document no')}</td>
                        <td colSpan={'2'}>
                            <input
                                name="no"
                                type={'text'}
                                className="form-control form-control-sm d-print-noline"
                                // readOnly={editGroup.includes(form.status) || controller.viewData}
                                readOnly={controller.viewData}
                                defaultValue={form.no || ''}
                                onChange={(el) => updateForm('no', el.target.value)}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td>{t('document source')}</td>
                        <td colSpan={'2'}>
                            <input
                                name="source_no"
                                type={'text'}
                                className="form-control form-control-sm d-print-noline"
                                placeholder={t('input sale order number.')}
                                // readOnly={editGroup.includes(form.status) || controller.viewData}
                                readOnly={controller.viewData}
                                value={form.source_no || ''}
                                onChange={(el) => controller.getSource(el.target.value)}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td>{t('date')}</td>
                        <td colSpan={'2'}>
                            <DatePicker
                                selected={form.create_date ? new Date(form.create_date) : new Date()}
                                dateFormat="dd/MM/yyyy"
                                className="form-control form-control-sm d-print-noline"
                                // readOnly={editGroup.includes(form.status) || controller.viewData}
                                readOnly={controller.viewData}
                                onChange={(date) => updateForm('create_date', moment(date).toISOString())}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td className='text-center' width="2%">{t('No.')}</td>
                        <td className='text-center' width="20%">{t('rent detail')}</td>
                        <td className='text-center'>{t('Cost Code')}</td>
                        <td className='text-center'>{t('amount assets/matchine')}</td>
                        <td className='text-center'>{t('unit')}</td>
                        <td className='text-center'>{t('rent/day/baht')}</td>
                        <td className='text-center'>{t('date amount/day')}</td>
                        <td className='text-center' style={{ minWidth: 120 }}>{t('total(baht)')}</td>
                    </tr>
                </thead>

                <tbody className='font-12'>
                    {
                        form.items?.length > 0 && form.items.map((item, index) => (
                            <tr key={index + 1} id={item.uuid} >
                                <td className='text-center'>{index + 1}</td>
                                {/* <td>{item?.item_code}</td> */}
                                <td>
                                    {
                                        item.id === 1
                                            ? <input type='text'
                                                id={index}
                                                className='form-control form-control-sm d-print-noline font-11'
                                                value={item?.name || ''}
                                                onChange={(el) => updateLine('name', el)}
                                                readOnly={controller.viewData}
                                            />
                                            : item.name
                                    }

                                </td>
                                <td>
                                    <input type='text'
                                        className='form-control form-control-sm d-print-noline font-11'
                                        value={item?.cost_code || 'G200721'}
                                        onChange={(el) => updateLine('cost_code', el)}
                                    />
                                </td>
                                <td className=''>
                                    <input
                                        name="qty"
                                        type={'number'}
                                        className="form-control form-control-sm d-print-noline text-right"
                                        // readOnly={editGroup.includes(form.status) || controller.viewData}
                                        readOnly={controller.viewData}
                                        value={Number(item?.qty)}
                                        style={{ marginTop: -7 }}
                                        onChange={(el) => updateLine('qty', el)}
                                    />
                                </td>
                                <td>
                                    <div className="input-group" style={{ marginTop: '-5px' }}>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm text-left d-print-noline"
                                            name={`base_unit`}
                                            value={item.base_unit ? controller.unitOfmeasure.filter(el => el.id === Number(item.base_unit))[0].name : ''}
                                            readOnly={true}
                                        />
                                        <div className="input-group-append disabled-print">
                                            <button type="button" className="btn btn-outline-secondary btn-sm dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="sr-only">Toggle Dropdown</span>
                                            </button>
                                            <div className="dropdown-menu">
                                                {
                                                    controller.unitOfmeasure.length && controller.unitOfmeasure.map((element, i) => (
                                                        <button
                                                            className="dropdown-item"
                                                            id={element.id}
                                                            name="units"
                                                            key={i}
                                                            value={element.name}
                                                            disabled={controller.viewData}
                                                            onClick={(el) => updateLine('base_unit', el, index)}
                                                        >
                                                            {element.name}
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className='text-right'>
                                    <input
                                        name="price"
                                        type={'number'}
                                        className="form-control form-control-sm d-print-noline text-right"
                                        readOnly={controller.viewData}
                                        value={Number(item?.price)}
                                        style={{ marginTop: -7 }}
                                        onChange={(el) => updateLine('price', el)}
                                    />
                                </td>
                                <td className='text-right'>
                                    <input
                                        name="date_amount"
                                        type={'number'}
                                        className="form-control form-control-sm d-print-noline text-right"
                                        readOnly={controller.viewData}
                                        value={Number(item?.date_amount)}
                                        style={{ marginTop: -7 }}
                                        onChange={(el) => updateLine('date_amount', el)}
                                    />
                                </td>
                                <td className='text-right'>
                                    <div className=' position-relative'>
                                        {NumberFormat(((item.qty * item.date_amount * item.price) - (item.qty * item.date_amount * item.cost)) || 0)}
                                        <div
                                            className={`position-absolute disabled-print cursor-pointer ${controller.viewData ? 'd-none' : ''}`}
                                            style={{ top: 0, right: -30 }}
                                            onClick={el => deleteLine(item)}
                                            title={t('delete')}
                                        >
                                            <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))

                    }
                </tbody>
                {
                    !controller.viewData && <tbody className='font-12 disabled-print'>
                        <tr id="new-row">
                            <td className='text-center'>#</td>
                            <td colSpan={`2`}>
                                <div style={{ marginTop: '-6px', marginBottom: '-6px' }}>
                                    <SearchItem.itemCode
                                        controller={controller}
                                        name={`item_code`}
                                        value={form?.newCode}
                                        setValue={controller.setForm}
                                        setData={setSearchResult}
                                        nextInput='name'
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                }
                <tfoot>
                    {/* <tr>
                        <td className="font-11 ">
                            <span style={{ minWidth: 80 }}>{t('remark')}</span>
                        </td>
                        <td colSpan={'2'} className=" ">
                            <textarea
                                className="form-control d-print-noline font-11  text-info"
                                id="remark"
                                rows="1"
                                readOnly={controller.viewData}
                                value={form.remark || ''}
                                onChange={(el) => updateForm('remark', el.target.value)}
                            />
                        </td>
                    </tr> */}
                    {/* <tr>
                        <td colSpan={'6'} className="font-12">
                            <Signature Controller={controller} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={`6`} className={`disabled-print`}>
                            <FileUpload.FormUpload controller={controller} />
                        </td>
                    </tr> */}
                </tfoot>
            </table>

            <div className="row">
                <div className='col-8'>
                    <div className=' '>
                        <div className='font-12'>
                            **การชาระเงิน
                        </div>
                        <div className='col-8 border rounded font-11 p-2'>
                            ชาระค่าเช่า บัญชี บริษัท ธรรมศักดิ์ จากัด ธนาคารกรุงเทพ <br />
                            เลขที่บัญชี 101-3-47553-6 สาขา สานักงานใหญ่สีลม
                        </div>
                    </div>

                    <div className='mt-3 font-11'>
                        <span style={{ minWidth: 80 }}>{t('remark')}</span>
                        <textarea
                            className="form-control d-print-noline font-11  text-info"
                            id="remark"
                            rows="5"
                            readOnly={controller.viewData}
                            value={form.remark || `ผู้รับสินค้าห้ามดัดแปลง หรือกระทาการใดๆให้สินค้าที่เช่า
เกิดชารุดหรือเสียหาย หากผู้ให้เช่าตรวจเช็คแล้วพบสินค้าให้เช่า
ชารุดหรือเสียหาย ทางผู้ให้เช่าจะดาเนินการเรียกเก็บค่าเสียหาย
ในลาดับต่อไปหรือตามข้อตกลง`}
                            onChange={(el) => updateForm('remark', el.target.value)}
                        />
                    </div>
                </div>

                <div className="col-4">
                    <table className='table table-bordered font-12'>
                        <tbody>
                            <tr>
                                <td className='text-right p3-em'>ยอดรวม (บาท)</td>
                                <td className='text-right p3-em' style={{ minWidth: 100 }}>{NumberFormat(sumTotal.lineAmount)}</td>
                            </tr>
                            <tr>
                                <td className='text-right p3-em'>ส่วนลด (บาท)</td>
                                <td className='text-right p3-em' style={{ minWidth: 100 }}>{NumberFormat(sumTotal.totalDiscount)}</td>
                            </tr>
                            <tr>
                                <td className='text-right p3-em'>ยอดรวม (บาท)</td>
                                <td className='text-right p3-em' style={{ minWidth: 100 }}>{NumberFormat(sumTotal.afterDiscount)}</td>
                            </tr>
                            <tr>
                                <td className='text-right p3-em'>ภาษีมูลค่าเพิ่ม 7% (บาท)</td>
                                <td className='text-right p3-em' style={{ minWidth: 100 }}>{NumberFormat(sumTotal.vat)}</td>
                            </tr>
                            <tr>
                                <td className='text-right p3-em'>รวมยอด (บาท)</td>
                                <td className='text-right p3-em' style={{ minWidth: 100 }}>{NumberFormat(sumTotal.afterVat)}</td>
                            </tr>

                            <tr>
                                <td className='text-right p3-em'>ภาษีหัก ณที่จ่าย </td>
                                <td className='text-right p3-em' style={{ minWidth: 100 }}>
                                    <div className='d-flex justify-content-end '>
                                        <div className='' style={{ width: 85 }}>
                                            <input
                                                type="text"
                                                className='w-100 d-print-noline text-right'
                                                style={{ marginTop: '-10px' }}
                                                disabled={controller.viewData}
                                                value={Number(form.withholdingTax) || 0}
                                                onChange={(el) => controller.setForm({ ...form, withholdingTax: el.target.value })}
                                            />
                                        </div>
                                        <div className=' '> %</div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className='text-right p3-em'>ค่ามัดจา/ค่าประกันสินค้า</td>
                                <td className='text-right p3-em' style={{ minWidth: 100 }}>
                                    <div className='d-flex justify-content-end '>
                                        <div className='' style={{ width: 100 }}>
                                            <input
                                                type="text"
                                                className='w-100 d-print-noline text-right'
                                                style={{ marginTop: '-10px' }}
                                                disabled={controller.viewData}
                                                value={Number(form.securityDeposit) || 0}
                                                onChange={(el) => controller.setForm({ ...form, securityDeposit: el.target.value })}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className='text-right p3-em font-14'>ยอดชาระ</td>
                                <td className='text-right p3-em' style={{ minWidth: 100 }}>{NumberFormat(sumTotal.totals)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='disabled-print mb-5 text-right'>
                        <BootstrapSwitchButton
                            checked={form.vat_type === 1 ? true : false}
                            onlabel={t('include Vat')}
                            offlabel={t('exclude Vat')}
                            onChange={(checked) => { controller.setForm({ ...form, vat_type: checked ? 1 : 0 }) }}
                            size='xs'
                            width={100}
                            onstyle="primary"
                            offstyle='info'
                            id='product-Status'
                        />
                    </div>
                </div>

            </div>

            <div className='row'>
                <div className="col">
                    <div className='border rounded d-flex justify-content-between font-12 py-2' style={{ paddingLeft: 50, paddingRight: 50 }}>

                        <div className='text-center'>
                            <div>ผู้ออกเอกสาร :</div>

                            <div className='mt-5'>ณรงศักดิ์</div>
                            <div>วันที่ {moment().format('D')} /{moment().format('M')} /{moment().add('543', 'years').format('YY')}</div>
                        </div>

                        <div className='text-center'>
                            <div>ผู้ส่งสินค้า :</div>

                            <div className='mt-5'>......................................</div>
                            <div>วันที่......../......../................</div>
                        </div>

                        <div className='text-center'>
                            <div>ผู้รับสินค้า :</div>

                            <div className='mt-5'>......................................</div>
                            <div>วันที่......../......../................</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
}


const BillingNoteApi = {
    search,
    getData,
    TableList,
    FormAddData,
    remove,
    create,
    update
};

export default BillingNoteApi;