import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import uuid from 'react-uuid';
import moment from 'moment';
import _global from './global';

const { client } = require('./auth').default;

const getOrderId = async () => {
    try {
        const { data: [{ max }] } = await client.get(`/v1/orders?max=true`);
        return max + 1;
    } catch (error) {
        console.error('GET Order::', error);
    }
}

const searchOrder = async (search) => {
    try {
        const { data } = await client.get(`/v1/orders/`, {
            params: {
                search: search
            }
        });
        return data;
    } catch (error) {
        console.error('GET Order by id::', error);
    }
}

const getOrderById = async (id) => {
    try {
        const { data } = await client.get(`/v1/orders/${id}`);
        return data;
    } catch (error) {
        console.error('GET Order by id::', error);
    }
}

const getOrderList = async (from, to, search) => {
    try {
        const response = await client.get(`/v1/orders`, {
            params: {
                from: from,
                to: to,
                search: search.trim()
            }
        });
        return response.data;
        // let orders = [];
        // for(let item of response.data){
        //     // get item lines
        //     let line = await getOrderLine(item.id);
        //     orders.push({...item, ...{ items: line}});
        // }

        // console.log('order list *******',orders)
        // return orders;
    } catch (error) {
        console.error('GET Order::', error);
    }
}

const getOrderLine = async (id) => {
    try {
        const response = await client.get(`/v1/orders/line/${id}`);
        let newLine = []
        for (const element of response.data) {
            let { data: item } = await client.get(`/products/${element.item}`);
            newLine.push({
                ...element,
                setting: element.setting && JSON.parse(element.setting),
                prices: item.prices && JSON.parse(item.prices) || { [t('days')]: '1' },
                time_unit: element.time_unit || item.prices && Object.keys(JSON.parse(item.prices))[0] || t('days'),
                multiply_unit: element.time_unit && item.prices && JSON.parse(item.prices)[element.time_unit] || 0,
                units: item.unit && JSON.parse(item.unit) || { [t('unit')]: t('unit') },
                unit: element.unit || item.unit && Object.keys(JSON.parse(item.unit))[0] || t('unit'),
                uuid: uuid()
            })
        }
        return newLine;
    } catch (error) {
        console.error(error);
    }
}

const getOrderPdf = async (from, to) => {
    try {
        const response = await client.get(`/pdf/orders/1`);
        return response;
    } catch (error) {
        console.error(error);
    }
}

const remove = async (id) => {
    try {
        return await client.delete(`/v1/orders/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}

const updateHeader = async (body, id) => {
    try {
        let response = await client.patch(`/v1/orders/${id}`, body);
        return response.data;
    } catch (e) {
        console.error('error::', e);
    }
}

const updateOrder = async (data) => {
    try {
        let header = data.header;
        let lines = data.line;

        let updateLine = [];
        /**
         * Clear Line First
         */
        let del = await client.delete(`/v1/orders/lines/${header.id}`);
        //console.log('clear line::', del);

        for (const el of lines) {
            let { data: item } = await client.get(`/products/${el.item}`);

            delete el.uuid;
            delete el.user;
            delete el.comp;
            delete el.prices;
            delete el.units;
            delete el.multiply_unit;
            el.setting = JSON.stringify(el.setting);
            el.orders = header.id;
            console.log('create line >>', el);
            let { data: { data } } = await client.post('/v1/orders/line', el);

            updateLine.push({
                ...data,
                prices: item.prices && JSON.parse(item.prices) || { [t('days')]: '1' },
                time_unit: el.time_unit || item.prices && Object.keys(JSON.parse(item.prices))[0] || t('days'),

                units: item.unit && JSON.parse(item.unit) || { [t('unit')]: t('unit') },
                setting: el.setting && JSON.parse(el.setting),
                uuid: uuid()
            });

        }

        header.items = JSON.stringify(updateLine);
        //console.log('>>> header::', header);
        let { data: response } = await client.patch(`/v1/orders/${header.id}`, header);

        //console.log('update line::', updateLine)
        //console.log('update header::', response.data)
        let newHeader = response.data;
        newHeader.items = JSON.parse(newHeader.items) || [];
        newHeader.attach = JSON.parse(newHeader.attach) || [];
        return {
            header: newHeader,
            line: updateLine
        };
    } catch (e) {
        console.error('error::', e);
    }
}

const createOrder = async () => {
    try {
        let body = {
            "user": await _global.getUserFromToken(),
            "document_no": 'RTK-' + moment().format('YYMMDD'),
            "order_date": moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            "items": "",
            "cost": 0,
            "amount": 0,
            "total_amount": 0,
            "discount": 0,
            "total_discount": 0,
            "cust_name": "ทั่วไป",
            "cust_cid": "",
            "cust_mobile": "",
            "cust_project": "",
            "cust_place": "",
            "start_date": moment().startOf('month').format('YYYY-MM-DDT08:00:00.000Z'),
            "end_date": moment().endOf('month').format('YYYY-MM-DDT18:00:00.000Z'),
            "gps": "",
            "ref1": "",
            "ref2": "",
            "ref3": "",
            "status": 0,
            "deposit": 0,
            "uuid": uuid()
        }
        console.log('>>> CREATE HEADER', body)
        const { data: response } = await client.post(`/v1/orders`, body);
        console.log('<<< RES CREATE HEADER', response)

        let res = await updateHeader({ document_no: response.data.document_no + response.data.id }, response.data.id);

        return res.data;
    } catch (e) {
        console.error('error::', e);
    }
}

const OrdersApi = {
    getOrderId,
    getOrderById,
    getOrderPdf,
    getOrderList,
    getOrderLine,
    updateHeader,
    updateOrder,
    createOrder,
    remove,
    searchOrder
};

export default OrdersApi;