import React, { useEffect, useState } from 'react';
import Template from '../../component/common/template';
import ApprovalSetupApi from '../../api/approval_setup';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { faBan, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment/moment';
import _global from '../../api/global';
import Waiting from '../../component/waiting';
import UserApi from '../../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ApprovalSetup = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({});
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getData();
        getUser();
    }, []);

    const getUser = async () => {
        try {
            /**
                * get user
                */
            let userList = await UserApi.getData();
            console.log('userList::', userList)
            setUsers(userList);
        } catch (e) {
            console.warn(e);
        }
    }

    const getData = async () => {
        setLoading(true);

        setTimeout(async () => {

            let { data: res } = await ApprovalSetupApi.approveSetup();

            console.log('<<< data::', res);
            let sort = [...res].sort((a, b) => {
                var c = new Date(a.datetime);
                var d = new Date(b.datetime);
                return d - c;
            });

            setData(sort);
            setLoading(false);
        }, 300);



    }

    const saveApproval = async (row) => {
        setLoading(true);
        try {
            let body = _.cloneDeep(row);
            delete body.id;
            console.log('body save >>>', body);
            let res = await ApprovalSetupApi.update(body, row.id);

            console.log('<<< data::', res);
            getData();

            setTimeout(() => {
                setLoading(false);
                setForm({});
            }, 1000)

        } catch (e) {
            console.warn(e);
        }
    }

    const controller = ({

    })


    const Title = ({ controller }) => {
        return <>
            <div className='row'>
                <div className='col'>
                    <h3 className=" mb-2 text-gray-800">{t('Approval Setup')}</h3>
                </div>
                <div className='col text-right'>

                </div>
            </div>

        </>
    }

    const ExpandedComponent = ({ data }) => {

        let payload = _.clone(data);

        return <div className='w-100 font-11' style={{ borderLeft: "1px solid #ccc", borderRight: "1px solid #ccc" }}>
            <div className=' '>
                <div>{payload?.source_des}</div>
            </div>
            <div style={{ backgroundColor: "#f5f5f5" }}>
                <pre className='font-9'>{JSON.stringify(payload, null, 2)}</pre>
            </div>
        </div>
    };

    return (
        <Template>
            <Waiting loading={loading} />
            {
                Object.keys(form).length > 0 &&

                <div className='form-data border my-5 shadow p-5 rounded'>
                    <div className="row">
                        <div className="col-6">
                            <div className="row">

                                <div className="col-12">
                                    <label htmlFor='source_table'>{t('system')}</label>
                                    <select id="source_table"
                                        className='form-control'
                                        value={form.source_table || ''}
                                        onChange={(el) => setForm({ ...form, source_table: el.target.value })}
                                    >
                                        <option value={'journal'}>journal</option>
                                        <option value={'orders'}>orders</option>
                                    </select>
                                </div>

                                <div className="col-12 mt-3">
                                    <label>{t('system name')}</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={form.source_title || ''}
                                        onChange={(el) => setForm({ ...form, source_title: el.target.value })}
                                    />
                                </div>

                                <div className="col-12 mt-3">
                                    <div className="row">
                                        <div className="col-3">

                                            <label>{t('ลำดับการแสดง')}</label>
                                            <input
                                                type="text"
                                                className='form-control '
                                                value={form.position_step || ''}
                                                onChange={(el) => setForm({ ...form, position_step: el.target.value })}
                                            />

                                        </div>
                                        <div className="col-9">
                                            <label htmlFor='user'>{t('approve by')}</label>
                                            <select id="user"
                                                className='form-control'
                                                value={form.user || 0}
                                                onChange={(el) => setForm({ ...form, user: el.target.value })}
                                            >
                                                <option value={0}>{t('not set')}</option>
                                                {
                                                    users.length && users?.map((el, index) => (
                                                        <option key={index + 1} value={el.id}>
                                                            {el.f_name_th} {el.l_name_th}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 mt-3">
                                    <div className="row">
                                        <div className="col-6">

                                            <label htmlFor='rules'> {t('Rules')}</label>
                                            <select id="rules"
                                                className='form-control'
                                                value={form.rules || 0}
                                                onChange={(el) => setForm({ ...form, rules: el.target.value })}
                                            >
                                                <option value={1}>{t('skip approve')}</option>
                                                <option value={2}>{t('reviewer')}</option>
                                                <option value={3}>{t('appover')}</option>
                                            </select>
                                        </div>
                                        <div className="col-6 text-right">

                                            <label htmlFor='approve-status' className='mr-2'>{t('Status')}   </label>
                                            <BootstrapSwitchButton
                                                checked={form.status === 1 ? true : false}
                                                onlabel={t('Active')}
                                                offlabel={t('Disable')}
                                                onChange={(checked) => { setForm({ ...form, status: checked ? 1 : 0 }) }}
                                                size="sm"
                                                width={100}
                                                onstyle="success"
                                                id='approve-status'
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="card"  >
                                <div className="card-body">


                                    <div className="row">

                                        <div className="col-12 mb-5 text-center">
                                            <label className='text-info'>{t('ส่วนหัว')}</label>
                                            <input
                                                type="text"
                                                className='form-control text-center'
                                                value={form.sign_title || ''}
                                                onChange={(el) => setForm({ ...form, sign_title: el.target.value })}
                                            />
                                        </div>

                                        <div className="col-12 mt-2 text-center">
                                            <label className='text-info'>{t('ชื่อที่แสดง')}</label>
                                            <div className='d-flex justify-content-between'>
                                                <div>{`(`}</div>
                                                <div><input
                                                    type="text"
                                                    className='form-control text-center'
                                                    value={form.format_row3 || ''}
                                                    onChange={(el) => setForm({ ...form, format_row3: el.target.value })}
                                                /></div>
                                                <div>{`)`}</div>
                                            </div>
                                        </div>

                                        {/* <div className="col-12 text-center">
                                        <label>{t('ลงชื่อ')}</label>
                                        <input
                                            type="text"
                                            className='form-control text-center'
                                            value={form.format_row1 || ''}
                                            onChange={(el) => setForm({ ...form, format_row1: el.target.value })}
                                        />
                                    </div> */}

                                        <div className="col-12 text-center">
                                            <label className='text-info'>{t('วันที่')}</label>
                                            <input
                                                type="text"
                                                className='form-control text-center'
                                                value={form.format_row2 || ''}
                                                onChange={(el) => setForm({ ...form, format_row2: el.target.value })}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='col text-right mt-5 '>
                            <button className='btn btn-info btn-sm'
                                onClick={(el) => saveApproval(form)}
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} />
                                <span className='ml-2'>{t('save')}</span>
                            </button>
                        </div>
                    </div>
                </div>
            }

            <DataTable
                title={<Title controller={controller} />}
                noDataComponent={t('There are no records to display')}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                data={data || []}
                expandableRows
                //expandableRowExpanded={row => true}
                //expandableRowDisabled={row => false}
                expandableRowsComponent={ExpandedComponent}
                columns={[
                    {
                        name: t('name'),
                        selector: row => row.format_row3 || ' ',
                        sortable: true,
                        minWidth: 200,
                        maxWidth: 220,
                        id: "format_row3"
                    },

                    {
                        name: t('system'),
                        selector: row => row.source_table,
                        sortable: true,
                        id: "source_table"
                    },

                    {
                        name: t('system name'),
                        selector: row => row.source_name,
                        sortable: true,
                        id: "source_name"
                    },

                    // {
                    //     name: t('approve by'),
                    //     selector: row => row.user,
                    //     sortable: true,
                    //     id: "approve_by"
                    // },

                    // {
                    //     name: <div className='w-100 text-center'>{t('create date')}</div>,
                    //     selector: row => moment(row.create_when).format('YYYY-MM-DD H:mm'),
                    //     cell: (row) => <div className={`w-100 text-center`}>{moment(row.create_when).format('YYYY-MM-DD H:mm')}</div>,
                    //     sortable: true,
                    //     minWidth: 100,
                    //     maxWidth: 120,
                    //     id: "create_when"
                    // },

                    {
                        name: t('status'),
                        selector: row => <div className={` ${_global.approveSetupStatus(row.status).class}`}>{_global.approveSetupStatus(row.status).text}</div>,
                        sortable: true,
                        id: "status"
                    },

                    {
                        name: t('Action'),
                        selector: row => row.source_table,
                        cell: (row) =>
                            <div className='w-100'>
                                <button
                                    className="btn btn-warning btn-xs ml-1"
                                    onClick={() => setForm(row)} >
                                    {t('edit')}
                                </button>
                                {/* <button
                                    className="btn btn-danger btn-xs ml-1"
                                    onClick={() => { }}
                                    data-toggle="modal"
                                    data-target="#modalDeleteJournal">
                                    {t('delete')}
                                </button> */}
                            </div>,

                        width: '120px',
                        id: "actions"
                    }

                ]}

                sortFunction={_global.customSort}
                defaultSortFieldId="code"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />


        </Template>
    );

}

export default ApprovalSetup;
