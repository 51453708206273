import React, { useEffect, useState } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import _global from './global';

const get = async (id) => {
    try {
        if (!id) { throw ('user id not found') }
        const response = await _global.client.get(`/v1/payment/${id}`);

        let data = {
            ...response.data,
            settings: response.data.settings ? JSON.parse(response.data.settings) : []
        };

        return data;
    } catch (e) {
        console.warn(e, id);
        return [];
    }
}

const getData = async () => {
    try {
        let response = await _global.client.get(`/v1/payment`);
        //console.log('response::', response.data)
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


const remove = async (id) => {
    try {
        return await _global.client.delete(`/v1/payment/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}

const update = async (params, id) => {
    try {
        let body = {
            ...params,
            settings: Object.keys(params.settings).length ? JSON.stringify(params.settings) : ""
        }
        console.log('>>> PATCH update', body);
        const response = await _global.client.patch(`/v1/payment/${id}`, body);
        console.log('<<< update RES::', response);
        return response;
    } catch (e) {
        console.error('<<< ERROR update', e);
        return {
            status: e.status,
            message: e.message
        }
    }
}

const FormAddData = ({ data, setData, setLoading, formData, setFormData, t }) => {
 
    useEffect(() => {

    }, [data])


    console.log('payment modal::', formData);

    return <div>
        <div className="">
            <div className="row">
                <div className="col-6">
                    <label htmlFor='product-price'>{t('Bank')}:</label>
                    <input className="form-control" value={formData.bank || ''} id={'bank'} onChange={e => setFormData({ ...formData, bank: e.target.value })} />
                </div>
            </div>
             
        </div>
    </div>
}


const PaymentApi = {
    get,
    getData,
    remove,
    update,
    FormAddData
};

export default PaymentApi;