import { useTranslation } from 'react-i18next';
import React from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



function Menu({ title, setPrices, modalShow }) {
    const { t } = useTranslation();
    // const modalAdd = (e, item) => {
    //     //console.log('element::', e, 'item::', item);
    //     setPrices({ [t('hours')]: 1 });
    // }

    return (
        <div className='col'>
            <div className='row'>
                <div className='col'>
                    <h1 className="h3 mb-2 text-gray-800">{t(title)}</h1>
                </div>
                <div className='col text-right'>
                    <button type="button" className='btn btn-outline-success btn-sm' onClick={(e) => modalShow(e, {})} data-toggle="modal" data-target="#addModalNew"><FontAwesomeIcon icon={faPlusCircle} /> {t('Add')}</button>
                </div>
            </div>
        </div>
    )
}

export default Menu;