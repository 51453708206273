import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _global from '../api/global';
import Menu from './menu';

import {
    faTrashAlt,
    faPowerOff,
    faEdit
} from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';



function MeasureTable({ data, MeasureApi, setFormData, setData }) {

    const { t } = useTranslation();
    const [units, setUnits] = useState([]);
    const [deleteItem, setDeleteItem] = useState(0);

    useEffect(() => {
        setUnits(data);
    }, [data])

    const modalShow = (e, item) => {
        setFormData({
            id: item.id,
            code: item.code,
            name: item.name,
            status: item.status
        })
    }

    const comfirmDelete = () => {
        let id = deleteItem;
        let data = units.filter((e) => e.id !== id);
        setUnits(data);
        setData(data);
        MeasureApi.remove(id)

        console.log('new data::', data)
    }

    const comfirm = () => {

    }

    /**
     * Data Table 
     * https://react-data-table-component.netlify.app/?path=/docs/getting-started-intro--page
     * 
     * */

     const columns = [
        {
            name: t('#'),
            sortable: true,
            cell: (item, index) => <div>{index+1}</div>,
            id: "index"
        },
        {
            name: t('code'),
            selector: row => row.code,
            sortable: true,
            id: "code"
        },
        {
            name: t('name'),
            selector: row => row.name,
            sortable: true,
            id: "name"
        },        
        {
            name: t('Action'),
            selector: row => row.id,
            id: "action",
            width: '150px',
            cell: (item) => <div className='w-100 text-right'>
                <button className="btn btn-warning btn-xs ml-1" onClick={(e) => modalShow(e, item)} data-toggle="modal" data-target="#addModalNew"><FontAwesomeIcon icon={faEdit} /> {t('edit')} </button>
                <button className="btn btn-danger btn-xs ml-1" onClick={(e) => setDeleteItem(item.id)} data-toggle="modal" data-target="#modalConfirm"><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')} </button>
            </div >
        }

    ];

    function DataTableBase(props) {
        return (
            <DataTable
                title={t('Product')}
                pagination
                selectableRowsComponent={Checkbox}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                columns={columns}
                data={units || []}
                sortFunction={_global.customSort}
                defaultSortFieldId="code"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },                    
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',                                
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />
        );
    }


    return (
        <div>
            <Menu title={t('Unit Of Measure')} name={t('Unit Of Measure')} />
            <div className="modal fade" id="modalConfirm" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-danger text-white">
                            <h5 className="modal-title">Confirm</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{t('comfirn delete')}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={comfirmDelete} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">{t('products')}</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <DataTableBase />                       
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MeasureTable;

