import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    faMinusCircle,
    faPenAlt,
    faSquarePlus,
    faPrint,
    faArrowTurnDown,
    faSearch,
    faCheck
} from '@fortawesome/free-solid-svg-icons';

import DatePicker, { setDefaultLocale } from "react-datepicker";
import global from '../../api/global';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

//https://react-bootstrap.github.io/components/modal/
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const SearchItem = ({
    show,
    setShow,
    searchItem,
    searchResult,
    pickItem,
    searchByName
}) => {
    const { t } = useTranslation();

    const [keyword, setKeyword] = useState('');


    const fetchData = async () => {
        try {

        } catch (e) {

        }
    }

    useEffect(() => {
        setDefaultLocale('th');
        fetchData().catch(console.error);
        document.querySelector(`input[name=search]`).focus();
    }, [show])

    return (
        <div className={`search-item  ${show ? 'd-block' : 'hide d-none'} p-2 shadow`}>
            <div className=''>
                <div className='text-right' style={{ position: 'fixed', right: 20, top: 10 }}>
                    <button className='btn' onClick={() => setShow(false)}>X</button>
                </div>
                <div className='mt-5'>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col-8 col-sm-4">
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    name="search"
                                    className="form-control"
                                    placeholder={t('search')}
                                    aria-label={t('search')}
                                    aria-describedby="button-addon2"
                                    onKeyDown={(el) => searchItem(el)}
                                    onChange={(el) => setKeyword(el.target.value)}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={(el) => searchByName(keyword)}>
                                        <FontAwesomeIcon icon={faSearch} className="mr-1" />
                                        {t('search')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='search-result'>
                    {searchResult.length > 0 &&
                        <table className='table table-bordered text-white'>
                            <thead>
                                <tr className='bg-dark'>
                                    <th> </th>
                                    <th>{t('products')}</th>
                                    <th>{t('Detail')}</th>
                                    <th>{t('detail.')}</th>
                                    <th className='text-right'>{t('select')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    searchResult?.map((item, index) => (
                                        <tr key={item.id + index}>
                                            <td>
                                                <label htmlFor={item.id}>
                                                    <img
                                                        src={`${global.config.api}/images/items/${(item.img)?.split(".")[0]}/${(item.img)?.split(".")[1] || 'jpg'}/small`}
                                                        className="img-fluid p-1" width="60px" name={item.img}
                                                        alt={item.alt || item.name}
                                                    />
                                                </label>
                                            </td>
                                            <td><label htmlFor={item.id}>{item.name}</label></td>
                                            <td><label htmlFor={item.id}>{item.description}</label></td>
                                            <td><label htmlFor={item.id}>{item.detail}</label></td>
                                            <td className='text-right'>
                                                <button id={item.id} className={`btn btn-success btn-xs`}
                                                    onClick={(el) => pickItem(el, item)}>
                                                    <FontAwesomeIcon icon={faCheck} className="mr-1" />
                                                    {t('select')}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    }
                </div>
            </div>

        </div>
    );


}

export default SearchItem;
