import { t } from 'i18next';
import moment from 'moment-timezone';
import jwt_decode from "jwt-decode";
import CategoryApi from './category';
const config = require(`../config/${process.env.NODE_ENV}`).default;
const { client } = require('./auth').default;

const getUserFromToken = async () => {
    let token = localStorage.getItem('token');
    //console.log('user >>', jwt_decode(token)['id']);
    return jwt_decode(token)['id'];
}

const getToken = () => {
    let token = localStorage.getItem('token');
    return jwt_decode(token);
}


const approveStatus = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 1:
            className = 'text-info';
            text = t('Inside');
            break;
        case 2:
            className = 'text-primary';
            text = t('Outside');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}

const getJournalStatus = (mode) => {
    let className = 'text-success';
    let type = 0;
    let text = '';
    switch (mode) {
        case 'inventory': // purchase receive
            className = 'text-info';
            text = 'เบิกสินค้า';
            type = 1;
            break;
        case 'receive': // purchase receive
            className = 'text-secondary';
            text = 'รับสินค้าเข้า';
            type = 2;
            break;
        case 'stock': // adjust stock
            className = 'text-warning';
            text = 'ปรับยอดสต๊อก';
            type = 3;
            break;
        default:
            className = 'text-danger';
            text = 'รับคืนสินค้า';
            type = 0;
            break;
    }
    return { class: className, type: type, text: text }
}

const JobStatusX = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 0:
            className = 'text-secondary';
            text = t('Created');
            break;
        case 1:
            className = 'text-warning';
            text = t('Pending');
            break;
        case 2:
            className = 'text-success';
            text = t('Confirmed');
            break;
        case 3:
            className = 'text-info';
            text = t('Waiting for payment');
            break;
        case 1:
            className = 'text-success';
            text = t('Paid');
            break;

        case 4:
            className = 'text-muted';
            text = t('Waiting for the transfer slip');
            break;
        case 5:
            className = 'text-success';
            text = t('Paid');
            break;
        case 9:
            className = 'text-muted';
            text = t('Cancled');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}

const ItemSource = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 1:
            className = 'text-info';
            text = t('Inside');
            break;
        case 2:
            className = 'text-primary';
            text = t('Outside');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}

const ItemType = (ItemType) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(ItemType)) {
        case 0:
            className = 'text-info';
            text = t('Finished Goods');
            break;
        case 1:
            className = 'text-primary';
            text = t('Assembly');
            break;
        case 2:
            className = 'text-primary';
            text = t('Raw Materials');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}

const CustomerStatus = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 0:
            className = 'text-secondary';
            text = t('Created');
            break;
        case 1:
            className = 'text-success';
            text = t('Active');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}

const UserStatus = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 0:
            className = 'text-secondary';
            text = t('Disabled');
            break;
        case 1:
            className = 'text-success';
            text = t('Active');
            break;
        case 3:
            className = 'text-warning';
            text = t('Suspend');
            break;
        case 4:
            className = 'text-danger';
            text = t('Deleted');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}


const PaymentStatus = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 0:
            className = 'text-secondary';
            text = t('Pay');
            break;
        case 1:
            className = 'text-info';
            text = t('Wait');
            break;
        case 2:
            className = 'text-success';
            text = t('Approved');
            break;

        default:
            className = 'text-danger';
            text = t('Not set');
            break;
    }
    return { class: className, text: text }
}


//0=Create, 1=Modify, 2=Pending, 3=Review, 4=Approved, 5=Canceled, 6=Reject
const MiddleStatus = (status) => {
    let className = 'text-success';
    let text = "";
    switch (parseInt(status)) {
        case 1:
            className = 'text-warning';
            text = t('Modify');
            break;
        case 2:
            className = 'text-info';
            text = t('Pending');
            break;
        case 3:
            className = 'text-primary';
            text = t('Review');
            break;
        case 4:
            className = 'text-success';
            text = t('Approved');
            break;
        case 5:
            className = 'text-secondary';
            text = t('Canceled');
            break;
        case 6:
            className = 'text-danger';
            text = t('Rejected');
            break;
        default:
            className = 'text-secondary';
            text = t('Create');
            break;
    }
    return { class: className, text: text }
}

const JournalStatus = MiddleStatus;
const JobStatus = MiddleStatus;
const approveSetupStatus = UserStatus;

const locations = () => {
    return [
        { id: 1, name: t('Bangkok') },
        { id: 2, name: t('Krabi') }
    ];
}

const NumberFormat = (value, digit = 2) => Number(value).toLocaleString(navigator.language, { minimumFractionDigits: digit });
/**
 * 
 * @param {*} lines 
 * @param {*} discount 
 * @param {*} withTax 
 * @param {*} header 
 * @param {*} includesVat | 1 = include vat | 0 = exclude Vat
 * @returns 
 */
const Totals = async (lines, discount = 0, withTax = 3, header) => {
    let lineAmount = 0;
    let totalAmount = 0;
    let totalCost = 0;
    let totalDiscount = 0;
    let vat7 = 0;
    let incVat = 0;
    let excVat = 0;
    let afterVat = 0;
    let totals = 0;
    let profitTotal = 0;
    let securityDeposit = header?.securityDeposit || 0; //เงินประกัน
    let vatType = header?.vat_type || 0; //1 = include vat(ใน) , 0 = exclude Vat(นอก)

    lines?.forEach(item => {
        lineAmount += parseFloat(item.qty) * parseFloat(item.date_amount) * parseFloat(item.price);
        totalAmount += parseFloat(item.date_amount);
        totalCost += parseFloat(item.date_amount) * parseFloat(item.cost);
    })

    /**
     * Total
     */
    totalDiscount = Number(discount || 0);

    totals = Number(lineAmount + vat7);
    profitTotal = (lineAmount - totalCost) - discount;
    incVat = Number(lineAmount - totalDiscount) * (100 / 107);
    excVat = Number(lineAmount - totalDiscount) * 7 / 100;

    vat7 = vatType === 0
        ? Number(lineAmount - totalDiscount) * 7 / 100
        : Number(lineAmount - totalDiscount) - incVat;
    //let totalCost = Number(line.date_amount * line.cost);
    console.log('header.vat_type::', header)

    let result = {
        lineAmount: lineAmount || 0,
        totalDiscount: totalDiscount || 0,
        totalCost: totalCost || 0,
        withholdingTax: withTax || 0,
        afterDiscount: vatType === 0
            ? (lineAmount - discount) || 0
            : incVat,
        vat: vat7 || 0,
        incVat: parseFloat(totals) - incVat || 0,
        excVat: excVat || 0,
        afterVat: vatType === 0
            ? parseFloat(lineAmount + vat7) || 0
            : parseFloat(lineAmount),
        totals: vatType === 0
            ? parseFloat(totals) + parseFloat(securityDeposit) + vat7  || 0
            : parseFloat(totals) + parseFloat(securityDeposit),
        profitTotal: profitTotal || 0,
        profitPercentage: (profitTotal / (totalCost > 0 ? totalCost : 1) * 100) || 0,
        profitSales: (profitTotal / lineAmount * 100) || 0,
        securityDeposit: securityDeposit
    }
    //console.log('total result::', result);
    return result;
}

const changeTime = (event, date) => {
    const time = event.target.value.split(':');
    let dateTime = moment(date).set({ h: time[0], m: time[1] }).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    console.log('date-time::', dateTime);
    return dateTime;
}

const numberOnly = (event) => {
    if (!/[0-9.]/.test(event.key)) event.preventDefault();
}

const getCatName = (id, category) => {
    let name;
    category.filter((cat) => (cat.id === id) ? name = cat.name : '')
    return name;
}

const mapCategory = async (data) => {
    let cat = await CategoryApi.getDataList();
    localStorage.setItem('category', JSON.stringify(cat));

    let newData = [];
    data?.sort((a, b) => {
        return a.name - b.name;
    });

    data?.map((el) =>
        newData.push({
            ...el,
            category_name: getCatName(el.category, cat)
        })
    )
    return newData;
}


const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
        // use the selector function to resolve your field names by passing the sort comparitors
        const aField = selector(rowA)
        const bField = selector(rowB)

        let comparison = 0;

        if (aField > bField) {
            comparison = 1;
        } else if (aField < bField) {
            comparison = -1;
        }

        return direction === 'desc' ? comparison * -1 : comparison;
    });
};

const clearCached = (key = 'products') => {
    if (key === 'products') {
        localStorage.removeItem('products');
    }
}

const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const itemImg = (item) => {
    return `${config.api}/images/items/${(item.img)?.split(".")[0]}/${(item.img)?.split(".")[1] || 'jpg'}/small`;
}

export default {
    approveStatus,
    approveSetupStatus,
    JobStatus,
    CustomerStatus,
    UserStatus,
    JournalStatus,
    PaymentStatus,
    ItemSource,
    itemImg,
    locations,
    NumberFormat,
    config,
    Totals,
    changeTime,
    getUserFromToken,
    numberOnly,
    getToken,
    mapCategory,
    client,
    customSort,
    clearCached,
    ItemType,
    getJournalStatus,
    formatBytes
};