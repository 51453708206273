import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const { client } = require('./auth').default;
const TableName = 'category';

 
const remove = async (id) => {
    try {
        return await client.delete(`/${TableName}/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}


const Add = ({
    TableName,
    id,
    name,
    detail,
    img,
    alt,
    list,
    setName,
    setDetail,
    setImg,
    setAlt,
    setList,
    handleChange,
    fileData
}) => {
    const [category, setCategory] = useState([]);

    useEffect(() => {
        let cat = localStorage.getItem(TableName) ? JSON.parse(localStorage.getItem(TableName)) : [];
        setCategory(cat);
    }, [])

    return <div>
        <div className="row">
            <input type="hidden" value={id} name="id" />
            <div className="col-8">
                <label htmlFor={`${TableName}-name`}>{t('Name')}:</label>
                <input type="text" value={name} className="form-control" id={`${TableName}-name`} name="name" placeholder={t(`${TableName} name`)} onChange={e => setName(e.target.value)} />
            </div>
             
            <div className="col">
                <label htmlFor={`${TableName}-alt`}>{t('Image name')}:</label>
                <input type="text" value={alt} className="form-control" id={`${TableName}-alt`} name="alt" placeholder={t("Alt")} onChange={e => setAlt(e.target.value)} />
            </div>
        </div>
        <div className="row mt-2">
            <div className="col-8">
                <label htmlFor={`${TableName}-detail`}>{t('Detail')}:</label>
                <textarea className="form-control" value={detail} id="exampleFormControlTextarea1" rows="3" onChange={e => setDetail(e.target.value)} />
                
                <label htmlFor={`${TableName}-list`}>{t('List')}:</label>
                <textarea className="form-control" value={list} id="exampleFormControlTextarea1" rows="3" onChange={e => setList(e.target.value)} />
            
            </div>
            <div className="col">
                <label htmlFor='validatedCustomFile'>{t('Image')}:</label>
                <div className="custom-file">
                    <label className="custom-file-label" htmlFor="validatedCustomFile">{fileData.name || `Choose image...` }</label>
                    <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="validatedCustomFile"
                        onChange={handleChange}
                        accept=".jpg,.png"
                    />
                    <div className="invalid-feedback">Example invalid custom file feedback</div>
                </div>
                {
                    fileData.src && <div className="card mt-2">
                        <div className="card-body p-0 text-center">
                            <img src={fileData.src} className="img-fluid img-rounded" />
                        </div>
                    </div>
                }
            </div>
            
        </div>
        <div className="row mt-2 justify-content-end">
            
        </div>
         
    </div>
}

const getDataList = async () => {
    try {
        let categoryCached = localStorage.getItem('category') ? JSON.parse(localStorage.getItem('category')) : [];

        if(categoryCached.length){
            return categoryCached;
        }else{
            const response = await client.get(`/${TableName}`);
            localStorage.setItem('category', JSON.stringify(response.data));
            return response.data;
        }
        
    } catch (error) {
        console.error(error);
    }
}

const CategoryApi = {
    Add,
    remove,
    getDataList
};

export default CategoryApi;