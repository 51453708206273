import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Buffer } from 'buffer';
import _global from '../../api/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faArrowLeft, faCheck, faEllipsisVertical, faPaperclip, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../../fileUpload.css';
import ProductApi from '../../api/products';
import uuid from 'react-uuid';

/**
 * 
 * @param {form} requred
 *  
 */
const itemCode = ({ name, value, setValue, nextInput, setData, controller, setSearch, search }) => {

    const searchData = async (value) => {
        try {
            if (value === '...') {
                return [
                    {
                        "id": 1,
                        "code": "0",
                        "category": 1,
                        "comp": 1,
                        "bom": "",
                        "item_type": 1,
                        "item_source": 1,
                        "item_source_des": null,
                        "name": "ข้อความ",
                        "description": " ",
                        "img": "f8b60df2-f591-4da3-9d91-405ee477a6d2.png",
                        "alt": null,
                        "detail": " ",
                        "icon": null,
                        "url": null,
                        "cost": "0.00",
                        "price": "0.00",
                        "prices": "{\"ชม.\":1,\"วัน\":\"4\"}",
                        "properties": null,
                        "status": "Active",
                        "stock": "0.00",
                        "remaining": "0.00",
                        "unit": "{\"ชุด\":\"ชุด\"}",
                        "base_unit": 13,
                        "location": 1,
                        "create_by": 1,
                        "create_when": "",
                        "update_by": 75,
                        "update_when": "2023-02-10T08:56:15.230Z",
                        "create_by_name": "ผู้ดูแล",
                        "update_by_name": "admin",
                        "category_name": "อุปกรณ์ก่อสร้าง",
                        "qty": 1,
                        "item": 162,
                        "item_code": "FG-01-01-001"
                    }
                ]
            }

            let res = await ProductApi.search(value);
            console.log(`item search ${value}::`, res);

            return res;
        } catch (e) {
            console.warn(e);
        }
    }

    const handdleChange = async (event) => {
        //let value = event.target.value;
        
        if (event.key.toLowerCase() === "enter") {
            onClickSearch(value)
        }

    }

    const onClickSearch = async (value) => {
        console.log('value setSearch::', value);
        let items = await searchData(value);

        if (items.length === 1) {
            console.log('item-code items', items)
            let itemsList = controller.form.items || [];
            items[0].qty = 1; //default qty
            items[0].item = items[0].id;
            items[0].item_code = items[0].code;
            itemsList = itemsList.push(items[0]);
            items[0].uuid = uuid();
            console.log('itemsList ***', itemsList)
            setData(itemsList);
        } else {
            let newList = [];
            for (let item of items) {
                newList.push({
                    ...item,
                    uuid: uuid()
                })
            }
            setData(newList);
        }

    }



    return (
        <div className={``}>
            <label className='d-flex'>
                <div className="input-group mb-3">
                    <input
                        name={name}
                        type="text"
                        className={`form-control form-control-sm d-print-noline font-11 border-info`}
                        value={value || ''}
                        onKeyDown={handdleChange}
                        onChange={(el) => setValue({...controller.form, newCode: el.target.value})}
                        placeholder='ค้นหาสินค้า  หรือ  (ใส่ ... (จุดสามจุด เพื่อใส่ข้อความ))'
                        aria-label="Inventory Adjust"
                        aria-describedby="Adjust"
                    />
                    <div className="input-group-append" >
                        <button className="btn btn-outline-info btn-xs" type="button" onClick={() => onClickSearch(value)}>
                            <FontAwesomeIcon icon={faSearch} />
                            <span className='ml-2'> {t('search')}</span>
                        </button>
                    </div>
                </div>
                <div className='mt-1 ml-2'></div>
            </label>
        </div>
    )
};

const modal = ({ children, data, setData, controller }) => {

    const closeModal = () => {
        setData([]);
    }

    const insertLine = (item) => {
        item.qty = 1;
        item.uuid = uuid();
        item.item = item.id;
        item.item_code = item.code;
        let items = controller.form.items || [];
        items = items.push(item);
        setData(items);
        //controller.setForm({ ...controller.form, ...{ items: items } });
    }

    return (
        <>
            <div className={`${data?.length ? 'd-block' : 'd-none'} modal-search-items`}>
                <div className='text-right border-bottom'>
                    <div
                        className=''
                        style={{ paddingTop: 10, paddingRight: 20, paddingBottom: 10, color: '#fff' }}
                        onClick={() => closeModal()}
                    >
                        <FontAwesomeIcon icon={faTimes} className="btn-modal-file" />
                    </div>
                </div>
                {children}
                <div className='px-2'>
                    <table className='table text-white'>
                        <thead>
                            <tr>
                                <td> </td>
                                <td>{t('Code')} </td>
                                <td>{t('name')}</td>
                                <td>{t('description')}</td>
                                <td>{t('stock')} </td>
                                <td>{t('unit')} </td>
                                <td> </td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.length > 0 && data.map((item, index) => (
                                    <tr key={index + 1} className={` `}>
                                        <td className=' ' width={`10%`}>
                                            <img
                                                src={_global.itemImg(item)}
                                                className="img-fluid p-1" width="60px" name={item.img}
                                                alt={item.alt || item.name} id={item.id}
                                            />
                                        </td>
                                        <td className='' width={`15%`}> {item.code || '-'} </td>
                                        <td className='' width={`30%`}> {item.name}</td>
                                        <td className='' width={`50%`}> {item.detail}</td>
                                        <td className='text-right' width={`20%`}> {Number(item.stock)}</td>
                                        <td className='' width={`5%`}> {Object.values(item.unit ? JSON.parse(item.unit) : [])[0] || ''}</td>
                                        <td className='text-right' >
                                            <button
                                                className='mr-2 btn btn-info btn-sm'
                                                style={{ minWidth: 80 }}
                                                onClick={() => insertLine(item)}
                                            >
                                                <FontAwesomeIcon icon={faCheck} /> {t('select')}
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

const SearchItem = {
    itemCode,
    modal
};

export default SearchItem;