import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _global from '../api/global';
import Menu from './menu';

import {
    faTrashAlt,
    faPowerOff,
    faEdit,
} from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';


function MeasureTable({ data, CustomerApi, setFormData, setData }) {

    const { t } = useTranslation();
    const [customers, setCustomers] = useState([]);
    const [deleteItem, setDeleteItem] = useState(0);

    useEffect(() => {
        setCustomers(data);        
    }, [data])


    const modalShow = (e, item) => {
        setFormData({
            id: item.id,
            code: item.code,
            name: item.name,
            citizen: item.citizen,
            tax_id: item.tax_id,
            address: item.address,
            address2: item.address2,
            city: item.city,
            district: item.district,
            province: item.province,
            zipcode: item.zipcode,
            contact: item.contact,
            create_by: item.create_by,
            create_when: item.create_when,
            update_by: item.update_by,
            update_when: item.update_when,
            status: item.status
        })
    }

    const comfirmDelete = () => {
        let id = deleteItem;
        let data = customers.filter((e) => e.id !== id);
        setCustomers(data);
        setData(data);
        CustomerApi.remove(id)

        console.log('new data::', data)
    }

    const comfirm = () => {

    }

    /**
     * Data Table 
     * https://react-data-table-component.netlify.app/?path=/docs/getting-started-intro--page
     * 
     * */

    const columns = [
        {
            name: t('code'),
            selector: row => row.code,
            sortable: true,
            id: "code"
        },
        {
            name: t('name'),
            selector: row => row.name,
            sortable: true,
            id: "name"
        },
        {
            name: t('province'),
            selector: row => row.province,
            sortable: true,
            id: "province"
        },
        {
            name: t('contact'),
            selector: row => row.contact,
            sortable: true,
            id: "contact"
        },
        {
            name: t('status'),
            selector: row => row.status,
            sortable: true,
            id: "status",
            cell: (item) => <div className={`font-10 ${_global.CustomerStatus(item.status).class} `}>{_global.CustomerStatus(item.status).text}</div>,
            maxWidth: '50px'
        },
        {
            name: t('Action'),
            selector: row => row.id,
            id: "action",
            width: '150px',
            cell: (item) => <div className='w-100 text-right'>
                <button className="btn btn-warning btn-xs ml-1" onClick={(e) => modalShow(e, item)} data-toggle="modal" data-target="#addModalNew"><FontAwesomeIcon icon={faEdit} /> {t('edit')} </button>
                <button className="btn btn-danger btn-xs ml-1" onClick={(e) => setDeleteItem(item.id)} data-toggle="modal" data-target="#modalConfirm"><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')} </button>
            </div >
        }

    ];

    function DataTableBase(props) {
        return (
            <DataTable
                title={t('Customers')}
                pagination
                selectableRowsComponent={Checkbox}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                columns={columns}
                data={customers || []}
                sortFunction={_global.customSort}
                defaultSortFieldId="code"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },                    
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',                                
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />
        );
    }

    return (
        <div>
            <Menu title={t('Customers List')} name={t('Customers List')} />
            <div className="modal fade" id="modalConfirm" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-danger text-white">
                            <h5 className="modal-title">Confirm</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{t('comfirn delete')}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={comfirmDelete} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">{t('customer')}</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <DataTableBase />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MeasureTable;

