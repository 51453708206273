import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const axios = require('axios').default;
const config = require(`../config/${process.env.NODE_ENV}`).default;

const client = axios.create({
    baseURL: config.api,
});




const responseFacebook = (response) => {
    console.log(response);
}

const componentClicked = (res) => {
    console.log('res::', res);
}

const responseGoogle = (response) => {
    console.log(response);
}

const responseFail = (response) => {
    console.warn(response)
}


const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [wrongPasswd, setWrongPasswd] = useState('d-none');
    const { t } = useTranslation();
    const navigate = useNavigate();

    const Sigin = async (e) => {
        e.preventDefault();

        let body = {
            username: username,
            password: password
        }

        try {
            const response = await client.post(`/login`, body, {
                headers: {
                    domain: config.domain
                }
            });

            localStorage.setItem('token', response.data.token)
            navigate('/', { replace: true });
        } catch (error) {
            console.warn(`Warning\nYour password is incorrect or this account doesn't exist`);
            setWrongPasswd('d-block');
            localStorage.removeItem('token')
        }
    }
    //navigate('/', { replace: true });


    document.body.style.backgroundColor = "#2D318C";

    return (
        <div className="container">
            {/* Outer Row */}
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            {/* Nested Row within Card Body */}
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                        </div>
                                        <form className="user">
                                            <div className="form-group">
                                                <input onChange={e => setUsername(e.target.value) | setWrongPasswd('d-none')} type="email" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={e => setPassword(e.target.value) | setWrongPasswd('d-none')} type="password" className="form-control form-control-user" id="exampleInputPassword" placeholder="Password" />
                                                <label className={`blink ml-3 font-11 text-danger ${wrongPasswd}`}>{t(`Your password is incorrect or this account doesn't exist`)}</label>
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox small">
                                                    <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                    <label className="custom-control-label" htmlFor="customCheck">Remember
                                                        Me</label>
                                                </div>
                                            </div>
                                            <button onClick={(e) => Sigin(e)} className="btn btn-primary btn-user btn-block">
                                                Login
                                            </button>
                                            <hr />
                                            <a href="index.html" className="btn btn-google btn-user btn-block">
                                                <i className="fab fa-google fa-fw" /> Login with Google
                                            </a>
                                            <a href="index.html" className="btn btn-facebook btn-user btn-block">
                                                <i className="fab fa-facebook-f fa-fw" /> Login with Facebook
                                            </a>
                                        </form>
                                        <hr />
                                        <div className="text-center">
                                            <a className="small" href="forgot-password">Forgot Password?</a>
                                        </div>
                                        <div className="text-center">
                                            <a className="small" href="register">Create an Account!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
