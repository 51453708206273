import React, { useEffect, useState } from 'react';
import Template from '../../component/common/template';
import ApprovalSetupApi from '../../api/approval_setup';
import CompanyApi from '../../api/company';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { faBan, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment/moment';
import _global from '../../api/global';
import Waiting from '../../component/waiting';
import UserApi from '../../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";


const Company = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({});
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getData();
        getUser();
        // {
        //     "id": 1,
        //     "name": "บริษัท ธรรมศักดิ์ จำกัด",
        //     "name_en": "THAMMASAK CO.,Ltd.",
        //     "detail": null,
        //     "img": "thammasak-q.png",
        //     "address": "89 ม.2 ต.คลองพน 81170อ.คลองท่อม จ.กระบี่ ",
        //     "website": "www.thammasak.co.th",
        //     "tax": "0745554004575",
        //     "alt": null,
        //     "data": {
        //       "email": "info@thammasak.co.th",
        //       "add_en": "89 M2 T.KLONGPON A.KLONGTOM KRABI 81170",
        //       "signer": "นายมานพ ต้นบำรุง",
        //       "bank": "ธนาคารกรุงเทพ เลขที่บัญชี 101-3-47553-6 สาขา สำนักงานใหญ่ส"
        //     },
        //     "status": 1
        //   }
    }, []);

    const getUser = async () => {
        try {
            /**
                * get user
                */
            let userList = await UserApi.getData();
            console.log('userList::', userList)
            setUsers(userList);
        } catch (e) {
            console.warn(e);
        }
    }

    const getData = async () => {
        setLoading(true);

        try {
            setTimeout(async () => {

                let { data: res } = await CompanyApi.search();

                console.log('<<< data::', res);
                let sort = [...res].sort((a, b) => {
                    var c = new Date(a.datetime);
                    var d = new Date(b.datetime);
                    return d - c;
                });

                setData(sort);
                setLoading(false);
            }, 300);
        } catch (e) {
            console.warn(e);
            setLoading(false);
        }

    }

    const controller = ({

    })

    /**
     * Auto Form
     */
    const schema = {
        title: t('edit') + ' ' + t('companies'),
        type: "object",
        required: ["name"],
        properties: {
            name: { type: "string", title: t('company'), default: form.name },
            name_en: { type: "string", title: t('english name'), default: form.name_en },
            branch_id: { type: "string", title: t('branch number'), default: form.branch_id, },
            head_office: { type: "boolean", title: t('head office'), default: form.head_office },

            branch_name: { type: "string", title: t('branch'), default: form.branch_name, },
            // title: { type: "string", title: "Title", default: "A new task" },
            // done: { type: "boolean", title: "Done?", default: false }
            status: {
                type: "string",
                title: t('status'),
                default: form.status === 1 ? t('Active') : t('Disabled'),
                "enum": [
                    t('Active'),
                    t('Disabled')
                ]
            },
        }
    };

    const log = (type) => console.log.bind(console, type);

    const submitForm = async (event) => {
        console.log('submit >>', event)
        setLoading(true);
        try {
            let body = _.cloneDeep(event.formData);
            body.status = body.status === t('Active') ? 1 : 0;
            body.head_office = body.head_office ? 1 : 0;
            console.log('body save >>>', body);
            let res = await CompanyApi.update(body, form.id);

            console.log('<<< response data::', res);
            getData();

            setTimeout(() => {
                setLoading(false);
                setForm({});
            }, 1000)

        } catch (e) {
            console.warn(e);
        }
    }


    const Title = ({ controller }) => {
        return <>
            <div className='row'>
                <div className='col'>
                    <h3 className=" mb-2 text-gray-800">{t('companies')}</h3>
                </div>
                <div className='col text-right'>

                </div>
            </div>

        </>
    }

    const ExpandedComponent = ({ data }) => {

        let payload = _.clone(data);

        return <div className='w-100 font-11' style={{ borderLeft: "1px solid #ccc", borderRight: "1px solid #ccc" }}>
            <div className=' '>
                <div>{payload?.source_des}</div>
            </div>
            <div style={{ backgroundColor: "#f5f5f5" }}>
                <pre className='font-9'>{JSON.stringify(payload, null, 2)}</pre>
            </div>
        </div>
    };

    return (
        <Template>
            <Waiting loading={loading} />

            {
                Object.keys(form).length > 0 &&
                <div className=' mb-5'>
                    <Form schema={schema}
                        validator={validator}
                        onChange={log("changed")}
                        onSubmit={submitForm}
                        onError={log("errors")} >
                        <div className='text-right'>

                            <button type="button"
                                className='btn btn-secondary btn-sm'
                                onClick={() => setForm({})}
                            >
                                <FontAwesomeIcon icon={faBan} />
                                <span className='ml-1'>{t('cancel')}</span>
                            </button>
                            <button type="submit" className='btn btn-success btn-sm ml-3' >
                                <FontAwesomeIcon icon={faFloppyDisk} />
                                <span className='ml-1'>{t('save')}</span>
                            </button>
                        </div>
                    </Form>

                </div>
            }

            <DataTable
                title={<Title controller={controller} />}
                noDataComponent={t('There are no records to display')}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                data={data || []}
                expandableRows
                //expandableRowExpanded={row => true}
                //expandableRowDisabled={row => false}
                expandableRowsComponent={ExpandedComponent}
                columns={[
                    {
                        name: t('company name'),
                        selector: row => row.name + ' ' + row.branch_name,
                        sortable: true,
                        minWidth: 200,
                        maxWidth: 250,
                        id: "name"
                    },

                    {
                        name: t('English name'),
                        selector: row => row.name_en,
                        sortable: true,
                        id: "name_en"
                    },

                    {
                        name: t('tax'),
                        selector: row => row.tax,
                        sortable: true,
                        id: "tax"
                    },



                    {
                        name: t('status'),
                        selector: row => <div className={` ${_global.approveSetupStatus(row.status).class}`}>{_global.approveSetupStatus(row.status).text}</div>,
                        sortable: true,
                        id: "status"
                    },

                    {
                        name: t('Action'),
                        selector: row => row.source_table,
                        cell: (row) =>
                            <div className='w-100'>
                                <button
                                    className="btn btn-warning btn-xs ml-1"
                                    onClick={() => setForm({ ...row, head_office: row.head_office === 1 ? true : false })} >
                                    {t('edit')}
                                </button>
                                {/* <button
                                    className="btn btn-danger btn-xs ml-1"
                                    onClick={() => { }}
                                    data-toggle="modal"
                                    data-target="#modalDeleteJournal">
                                    {t('delete')}
                                </button> */}
                            </div>,

                        width: '120px',
                        id: "actions"
                    }

                ]}

                sortFunction={_global.customSort}
                defaultSortFieldId="code"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />


        </Template>
    );

}

export default Company;
