import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import CustomerApi from '../api/customer';
import Template from '../component/common/template';
import CustomerTable from '../component/customer-table';
import ModalNew from '../component/modalNew';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk, faPowerOff
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import global from '../api/global';
import Waiting from '../component/waiting';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';


const Customer = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                let response = await CustomerApi.getData();
                setData(response);
            } catch (e) {
                console.error('error get customer::', e)
            }

        }
        fetchData().catch(console.error);


    }, [])

    const submitForm = async (e) => {
        e.preventDefault();
        //console.log('submit data::', formData);
        try {

            setLoading(true);
            if (!isNaN(formData.id)) {
                //update to db
                //console.log('do update')
                let body = _.omit(formData, 'id');
                let res = await CustomerApi.updateData(body, formData.id);
                //console.log('res updateData::', res);
                if (res.data.status === 2) {

                    let index = _.findIndex(data, { id: formData.id });
                    // Replace item at index using native splice
                    data.splice(index, 1, res.data.data);

                    setData(data);

                    setTimeout(() => {
                        setLoading(false);
                        $('body').find('button.close').click();
                    }, 500)
                    return res.data;
                }

            } else {

                //add to db
                //console.log('do create')
                let res = await CustomerApi.newData(formData);
                //console.log('res newData::', res);
                if (res.data.status === 2) {
                    let newData = _.clone(data);
                    newData.push(res.data.data);

                    setData(newData);

                    setTimeout(() => {
                        setLoading(false);
                        $('body').find('button.close').click();
                    }, 500)
                    return res.data;
                }
            }
        } catch (e) {
            console.error('error::', e);
        }
    };

    const closeModal = () => {
        /**
         * Clear data after close modal
         */


        document.getElementById("close-addModalNew").click();
    }


    return (
        <Template >
            <Waiting loading={loading} />
            <ModalNew
                submitForm={submitForm}
                ModalName={t('Customers')}
                closeModal={closeModal}
                mode={'modal-fullscreen'}
                bg={'bg-primary'}
            >
                <CustomerApi.FormAddData
                    data={data}
                    setData={setData}
                    setLoading={setLoading}
                    formData={formData}
                    setFormData={setFormData}
                />
            </ModalNew>
            <CustomerTable data={data} CustomerApi={CustomerApi} setFormData={setFormData} setData={setData} />
        </Template>
    );
}

export default Customer;
