import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker, { setDefaultLocale } from "react-datepicker";
import global from '../../api/global';
import "react-datepicker/dist/react-datepicker.css";
import CustomerApi from '../../api/customer';
import OrdersApi from '../../api/orders';
import moment from 'moment';
import _ from 'lodash';

const OrderHeader = ({
    dataModal: { countDate, company, ...order },
    setOrdersList, ordersList,
    updateHeader, enableEdit, setDataModal, setShowSearchResult, showSearchResult, afterSearch, setAfterSearch
}) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [customers, setCustomers] = useState({});


    const searchCustomerByName = async () => {
        console.log('search by name::', search)
        let res = await CustomerApi.search(search);
        console.log('res >>', res);
        setCustomers(res);
        setShowSearchResult(true);
    }

    useEffect(() => {
        //console.log('header loaded')
        setShowSearchResult(false);
        setDefaultLocale('th');
        const delayHook = setTimeout(() => {
            if (search) searchCustomerByName(search);
        }, 500)
        setAfterSearch(search);
        return () => clearTimeout(delayHook)

    }, [search]);


    const searchChange = (value) => {
        setDataModal({
            ...order,
            header: {
                ...order.header,
                "cust_name": value
            },
            ...countDate,
            ...company
        });
        setSearch(value);
    }


    const selectCustomer = async (event, customer) => {

        setShowSearchResult(false);
        setAfterSearch(customer.name)
        let body = {
            cust_name: customer.name,
            cust_cid: customer.tax_id,
            cust_mobile: customer.name,
            cust_place: customer.province,
            cust_project: customer.name,
            cust_contact: customer.contact,
        }
        //let res = await OrdersApi.updateHeader(body, order.header.id);

        let data = {
            countDate,
            company,
            ...order,
            header: {
                ...order.header,
                ...body
            }
        }

        setDataModal(data)

        // let index = _.findIndex(ordersList, { id: order.header.id });
        // // Replace item at index using native splice
        // ordersList.splice(index, 1, { ...data.header });

        setOrdersList(ordersList);
    }


    const SearchCustomers = () => {

        return (
            <div
                className={`shadow ${showSearchResult ? 'd-block' : 'd-none'}`}
                style={{
                    position: 'absolute',
                    left: 0,
                    width: '100%',
                    backgroundColor: '#d5e5f0',
                    zIndex: 1061,
                    padding: 15,
                    border: '1px solid #ccc',
                    borderRadius: 5
                }}>
                <hr />
                {
                    customers.map((el, index) => (
                        <div key={index + 1} style={{ padding: 10 }} onClick={(event) => selectCustomer(event, el)}>
                            <div className='row row-hover'>
                                <div className='col-2 bg-secondary text-white pb-2'><b>{el.code}</b></div>
                                <div className='col border border-secondary'><b>{el.name}</b></div>
                                <div className='col border border-secondary'>{el.province}</div>
                            </div>                            
                        </div>
                    ))
                }
            </div>
        )
    }

   
    return (
        <div className=' mb-2'>

            <h3 className='font-font-weight-bold text-center mt-2'>{t('Sale cost sheet')} <sub className='font-9 disabled-print'>{`${order.header?.id}`}</sub> </h3>
            <div className="row mt-5">

                {/* <div className="col-3 text-center">
                    <img src={`/assets/images/${company?.img}`} alt="thammasak-icon" className='img-fluid' minwidth={100} maxwidth={150} />
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col font-11">
                            {company?.name}
                        </div>
                        <div className="col font-9">
                            website: {company?.website}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col font-11">
                            {company?.name_en}
                        </div>
                        <div className="col font-9">
                            email: {company?.data.email}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div style={{ borderTop: '1px solid #ccc', width: '100%', height: 1 }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col font-9">
                            {company?.address}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col font-9">
                            {company?.data.add_en}
                        </div>
                    </div>
                </div> */}
            </div>


            <div className="row mt-3 font-13">
                <div className="col">
                    <div className="form-group row">
                        <label htmlFor='doc_no' className="col-3 col-sm-3 col-form-label ">{t('Number')}</label>
                        <div className="col">
                            <input
                                type="text"
                                className='form-control d-print-noline form-control-sm transparent-input '
                                defaultValue={order.header?.document_no || ''}
                                id="doc_no"
                                onChange={el => updateHeader('document_no', el.target.value)}
                                disabled={enableEdit === 'd-block' ? true : false}
                            />
                        </div>
                    </div>
                </div>
                <div className="col text-center  "> </div>
                <div className="col text-right" >
                    {t('PageNo')} 1/1
                </div>
            </div>


            <div className='mt-2  font-13'>

                <div className="row ">
                    <div className="col-3 col-sm-2 text-right">
                        <span className=''>{t('customer name')} :</span>
                    </div>
                    <div className="col" style={{ borderBottom: "1px solid #ccc", position: 'relative' }}>
                        <input type="text"
                            autoComplete='off'
                            defaultValue={order.header?.cust_name}
                            value={afterSearch}

                            onChange={el => searchChange(el.target.value)}
                            className="transparent-input w-100 "
                            disabled={enableEdit === 'd-block' ? true : false}
                        />
                        {customers.length > 0 && <SearchCustomers />}

                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-right">
                                {t('rentDate')} :
                            </div>
                            <div className="col p-0">
                                <DatePicker
                                    selected={new Date(order.header?.start_date || null)}
                                    onChange={(date) => updateHeader('start_date', moment(date).toISOString())}
                                    dateFormat="dd/MM/yyyy"
                                    className="transparent-input w-100"
                                    disabled={enableEdit === 'd-block' ? true : false}
                                />

                            </div>

                        </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-3 col-sm-2 text-right">
                        <span className=''>{t('citizenId')} :</span>
                    </div>
                    <div className="col" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            value={order.header?.cust_cid || ''}
                            onChange={el => updateHeader('cust_cid', el.target.value)}
                            className="transparent-input w-100"
                            disabled={enableEdit === 'd-block' ? true : false}
                        />
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-right">
                                {t('time')} :
                            </div>
                            <div className="col p-0 text-left">
                                <input
                                    type="time"
                                    value={moment(order.header?.start_date).format('HH:mm') || ''}
                                    onChange={el => updateHeader('start_date', global.changeTime(el, order.header?.start_date))}
                                    className="transparent-input w-100"
                                    disabled={enableEdit === 'd-block' ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-3 col-sm-2 text-right">
                        <span className=''>{t('phone')} :</span>
                    </div>
                    <div className="col-5 col-sm-7" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            value={order.header?.cust_mobile || ''}
                            onChange={el => updateHeader('cust_mobile', el.target.value)}
                            className="transparent-input w-100"
                            disabled={enableEdit === 'd-block' ? true : false}
                        />
                    </div>
                    <div className="col text-left font-10 ">
                        {t('dateOfReturn')} :
                    </div>
                </div>


                <div className="row ">
                    <div className="col-3 col-sm-2 text-right">
                        <span className=''>{t('project/place')}  :</span>
                    </div>
                    <div className="col" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            value={order.header?.cust_place || ''}
                            onChange={el => updateHeader('cust_place', el.target.value)}
                            className="transparent-input w-100"
                            disabled={enableEdit === 'd-block' ? true : false}
                        />
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-right">
                                {t('return date')} :
                            </div>
                            <div className="col p-0 text-left">
                                <DatePicker
                                    selected={new Date(order.header?.end_date || null)}
                                    onChange={(date) => updateHeader('end_date', moment(date).toISOString())}
                                    dateFormat="dd/MM/yyyy"
                                    className="transparent-input w-100"
                                    disabled={enableEdit === 'd-block' ? true : false}
                                />
                            </div>

                        </div>
                    </div>
                </div>


                <div className="row 1">
                    <div className="col-3 col-sm-2 text-right">
                        <span className=''>{t('entity/place')} :</span>
                    </div>
                    <div className="col" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            value={order.header?.cust_project || ''}
                            onChange={el => updateHeader('cust_project', el.target.value)}
                            className="transparent-input w-100"
                            disabled={enableEdit === 'd-block' ? true : false}
                        />
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className='row'>
                            <div className="col-7 text-right">
                                {t('time')} :
                            </div>
                            <div className="col p-0 text-left">
                                <input
                                    type="time"
                                    value={moment(order.header?.end_date).format('HH:mm') || ''}
                                    onChange={el => updateHeader('end_date', global.changeTime(el, order.header?.end_date))}
                                    className="transparent-input w-100"
                                    disabled={enableEdit === 'd-block' ? true : false}
                                />
                            </div>

                        </div>
                    </div>
                </div>


                <div className="row ">
                    <div className="col-3 col-sm-2 text-right">
                        <span className=''>{t('contact person')} :</span>
                    </div>
                    <div className="col-5 col-sm-7" style={{ borderBottom: "1px solid #ccc" }}>
                        <input
                            type="text"
                            value={order.header?.cust_contact || ''}
                            onChange={el => updateHeader('cust_contact', el.target.value)}
                            className="transparent-input w-100"
                            disabled={enableEdit === 'd-block' ? true : false}
                        />
                    </div>
                    <div className="col text-right">
                        <div className='row'>
                            {/* <div className="col-7 text-right font-12">
                                {t('all date rent')} :
                            </div>
                            <div className="col p-0 text-left">
                                <input
                                    type="text"
                                    defaultValue={countDate}
                                    className="transparent-input w-100"
                                    disabled={enableEdit === 'd-block' ? true : false}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );


}

export default OrderHeader;
