import { t } from 'i18next';
import React, { useEffect } from 'react';
import _global from './global';
import _ from 'lodash';


const searchByCid = async (cid) => {
    try {
        let response = await _global.client.get(`/v1/customer`, { params: { tax_id: cid } });
        //console.log('response::', response.data)
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const search = async (value) => {
    try {
        let response = await _global.client.get(`/v1/customer`, { params: { name: value } });
        //console.log('response::', response.data)
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const getData = async () => {
    try {
        let response = await _global.client.get(`/v1/customer`);
        //console.log('response::', response.data)
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const remove = async (id) => {
    try {
        return await _global.client.delete(`/v1/customer/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}


const newData = async (body) => {
    try {
        body.status = 1;
        return await _global.client.post(`/v1/customer`, body);
    } catch (e) {
        console.error('error::', e);
    }
}

const updateData = async (body, id) => {
    try {
        body.status = 1;
        return await _global.client.patch(`/v1/customer/${id}`, body);
    } catch (e) {
        console.error('error::', e);
    }
}



const FormAddData = ({ data, setData, setLoading, formData, setFormData }) => {


    useEffect(() => {

    }, [data])



    return <div>
        <div className="">
            <div className="row">
                <div className="col-6">
                    <label htmlFor='product-price'>{t('customers code')}:</label>
                    <input className="form-control" value={formData.code || ''} id={'code'} onChange={e => setFormData({ ...formData, code: e.target.value })} />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6  mt-4">

                    <label htmlFor='product-price'>{t('name')}:</label>
                    <input className="form-control" value={formData.name || ''} id={'name'} onChange={e => setFormData({ ...formData, name: e.target.value })} />

                    <label htmlFor='product-price'>{t('citizen id')}:</label>
                    <input className="form-control" value={formData.citizen || ''} id={'citizen'} onChange={e => setFormData({ ...formData, citizen: e.target.value })} />

                    <label htmlFor='product-price'>{t('tax id')}:</label>
                    <input className="form-control" value={formData.tax_id || ''} id={'tax_id'} onChange={e => setFormData({ ...formData, tax_id: e.target.value })} />

                    <label htmlFor='product-price'>{t('address')}:</label>
                    <input className="form-control" value={formData.address || ''} id={'address'} onChange={e => setFormData({ ...formData, address: e.target.value })} />

                    <label htmlFor='product-price'>{t('address')} 2:</label>
                    <input className="form-control" value={formData.address2 || ''} id={'address2'} onChange={e => setFormData({ ...formData, address2: e.target.value })} />

                </div>

                <div className="col-12 col-sm-6  mt-4">

                    <label htmlFor='product-price'>{t('district')}:</label>
                    <input className="form-control" value={formData.district || ''} id={'district'} onChange={e => setFormData({ ...formData, district: e.target.value })} />

                    <label htmlFor='product-price'>{t('city')}:</label>
                    <input className="form-control" value={formData.city || ''} id={'city'} onChange={e => setFormData({ ...formData, city: e.target.value })} />

                    <label htmlFor='product-price'>{t('province')}:</label>
                    <input className="form-control" value={formData.province || ''} id={'province'} onChange={e => setFormData({ ...formData, province: e.target.value })} />

                    <label htmlFor='product-price'>{t('zipcode')}:</label>
                    <input className="form-control" value={formData.zipcode || ''} id={'zipcode'} onChange={e => setFormData({ ...formData, zipcode: e.target.value })} />

                    <label htmlFor='product-price'>{t('contact')}:</label>
                    <input className="form-control" value={formData.contact || ''} id={'contact'} onChange={e => setFormData({ ...formData, contact: e.target.value })} />

                </div>
            </div>
        </div>
    </div>
}


const CustomerApi = {
    search,
    getData,
    FormAddData,
    remove,
    newData,
    updateData,
    searchByCid
};

export default CustomerApi;