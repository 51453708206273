import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import PaymentApi from '../../api/payments';
import Template from '../../component/common/template';
import ModalNew from '../../component/modalNew';
import TemplateSheet from '../../component/common/template-sheet';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit, faPowerOff, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import global from '../../api/global';
import Waiting from '../../component/waiting';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';




const Receipt = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [deleteItem, setDeleteItem] = useState(0);
    const [positions, setPositions] = useState({});
    const [viewData, setViewData] = useState(false);
    const [editable, setEditable] = useState(false);

    useEffect(() => {

        const fetchData = async () => {
            try {
                let response = await PaymentApi.getData();
                setData(response);
            } catch (e) {
                console.error('error get customer::', e)
            }

        }
        fetchData().catch(console.error);


    }, [])

    const submitForm = async (e) => {
        e.preventDefault();
        console.log('submit data::', formData);
        try {

            if (!isNaN(formData.id) && !formData.interrupt) {
                setLoading(true);
                //update to db
                //console.log('do update')
                let body = _.omit(formData, 'id');
                let res = await PaymentApi.updateData(body, formData.id);
                //console.log('res updateData::', res);
                if (res.data.status === 2) {

                    let index = _.findIndex(data, { id: formData.id });
                    // Replace item at index using native splice
                    data.splice(index, 1, { ...res.data, status: formData.status });

                    setData(data);

                    //console.log('updateed data ', data);

                    setTimeout(() => {
                        setLoading(false);
                        $('body').find('button.close').click();
                    }, 500)
                    return res.data;
                }

            }
        } catch (e) {
            console.error('error::', e);
        }
    };

    const closeModal = () => {
        /**
         * Clear data after close modal
         */


        document.getElementById("close-addModalNew").click();
    }

    const modalShow = (e, item) => {
        setFormData({
            id: item.id,
            email: item.email,
            f_name_th: item.f_name_th,
            l_name_th: item.l_name_th,
            phone: item.phone,
            username: item.username,
            status: item.status
        })
    }

    const modify = () => { //enable
        setFormData({}); // clear First
        setViewData(false)
        setEditable(true)
        setFormData(formData)
    }

    const controller = ({
        form: formData,
        setForm: setFormData,
        default: {
            create_date: new Date(),
            no: 'IN' + Date.now(),
            cust_address: '',
            items: [],
            positions: positions
        },
        modify,
        approve: {
            source_des: 'ใบวางบิล',
            source_table: 'billing_note',
            modalName: 'addModalBillingNote',
            updateSource: () => { }
        },
        signature: {
            creator: t('billing note'),
            system: 'billing_note',
            department: 'finance',
        },
        disableApprove: true
    })

    const ModalConfirmDelete = () => {
        const comfirmDelete = async () => {
            let id = deleteItem;
            let cachedData = _.cloneDeep(data);
            let newData = data.filter((e) => e.id !== id);
            setData(newData);
            let res = await PaymentApi.remove(id)
            if (res.data.status != 2) {
                setData(cachedData);
                alert(res.data.message);
            }
        }
        return (
            <div>
                <div className="modal fade" id="modalConfirm" tabIndex={-1} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-danger text-white">
                                <h5 className="modal-title">Confirm</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>{t('comfirn delete')}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={comfirmDelete} data-dismiss="modal" ><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')}</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FontAwesomeIcon icon={faPowerOff} /> {t('cancel')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    /**
     * Data Table 
     * https://react-data-table-component.netlify.app/?path=/docs/getting-started-intro--page
     * 
     * */

    const columns = [
        {
            name: t('Date'),
            selector: row => moment(row.payment_date).format('YYYY-MM-DD HH:mm:s'),
            sortable: true,
            id: "payment_date"
        },
        {
            name: t('ref'),
            selector: row => row.ref,
            sortable: true,
            id: "ref"
        },
        {
            name: t('bank'),
            selector: row => row.bank,
            sortable: true,
            id: "bank"
        },
        {
            name: t('ref1'),
            selector: row => row.ref1,
            sortable: true,
            id: "ref1"
        },
        {
            name: t('amount'),
            selector: row => global.NumberFormat(row.amount, 2),
            sortable: true,
            cell: (row) => <div className={` text-right`}>{global.NumberFormat(row.amount, 2)}</div>,
            id: "amount"
        },
        {
            name: t('status'),
            selector: row => row.status,
            sortable: true,
            id: "status",
            cell: (item) => <div className={`font-10 ${global.PaymentStatus(item.status).class} `}>{global.PaymentStatus(item.status).text}</div>,
            maxWidth: '50px'
        },
        {
            name: t('Action'),
            selector: row => row.id,
            id: "action",
            width: '150px',
            cell: (item) => <div className='w-100 text-right'>
                <button className="btn btn-warning btn-xs ml-1" onClick={(e) => modalShow(e, item)} data-toggle="modal" data-target="#addModalNew"><FontAwesomeIcon icon={faEdit} /> {t('edit')} </button>
                <button className="btn btn-danger btn-xs ml-1" onClick={(e) => setDeleteItem(item.id)} data-toggle="modal" data-target="#modalConfirm"><FontAwesomeIcon icon={faTrashAlt} /> {t('delete')} </button>
            </div >
        }

    ];

    const DataTableBase = (props) => {
        return (
            <DataTable
                title={t('Receipt')}
                pagination
                selectableRowsComponent={Checkbox}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 300]}
                selectableRowsComponentProps={{ indeterminate: isIndeterminate => isIndeterminate }}
                sortIcon={<ArrowDownward />}
                dense
                columns={columns}
                data={data || []}
                sortFunction={global.customSort}
                defaultSortFieldId="code"
                customStyles={{
                    header: {
                        style: {
                            minHeight: '56px',
                        },
                    },
                    headCells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            },
                            "&:last-child": {
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',
                                borderTopColor: '#ccc',
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc',
                                borderLeftStyle: 'solid',
                                borderLeftWidth: '1px',
                                borderLeftColor: '#ccc',
                                borderTop: "5px solid black",
                                borderBottom: "1px double #ccc"
                            }
                        },
                    },
                    rows: {
                        style: {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc'
                        },
                    },
                    cells: {
                        style: {
                            '&:not(:last-of-type)': {
                                borderRightStyle: 'solid',
                                borderRightWidth: '1px',
                                borderRightColor: '#ccc'
                            },
                        },
                    },
                }}
                highlightOnHover
                pointerOnHoverF
                {...props}
            />
        );
    }

    return (
        <Template >
            <TemplateSheet controller={controller}>
                <Waiting loading={loading} />
                <ModalConfirmDelete />
                <ModalNew
                    submitForm={submitForm}
                    ModalName={t('Customers')}
                    closeModal={closeModal}
                    mode={'modal-fullscreen'}
                    bg={'bg-primary'}
                >
                    <PaymentApi.FormAddData
                        data={data}
                        setData={setData}
                        setLoading={setLoading}
                        formData={formData}
                        setFormData={setFormData}
                        t={t}
                    />
                </ModalNew>
                <DataTableBase />
            </TemplateSheet>
        </Template>
    );
}

export default Receipt;
