import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApprovalApi from '../../api/approval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid';
import _global from '../../api/global';
import _ from 'lodash';

const SendApprove = ({ Controller }) => {
    const { t } = useTranslation();
    const form = Controller.form;


    useEffect(() => {


    }, [Controller])

    const btnSendApprove = () => {
        sendApprove();
        Controller.setConfirmApprove(false)
        console.log('send approve')
    }

    const sendApprove = async () => {
        try {
            Controller.setLoading(true);
            console.log('form send approve::', form);
            let body = {
                source_des: Controller.approve.source_des,
                source_table: Controller.approve.source_table,
                source_id: form.id,
                source_no: form.no,
                approve_when: "",
                gps: "0,0",
                payload: JSON.stringify(form),
                uuid: uuid(),
                type: 1
            }
            console.log('form send body >>>::', body)
            let { data: res} = await ApprovalApi.create(body);
            console.log('<<< res approval', res);


            Controller.approve.updateSource(res.data[0]);
            /**
             * clear status pending
             */
            // let bodyUpdate = _.cloneDeep(form);
            // bodyUpdate.items = typeof bodyUpdate.items === 'object' ? JSON.stringify(bodyUpdate.items) : bodyUpdate.items;
            // bodyUpdate.attach = typeof bodyUpdate.attach === 'object' ? JSON.stringify(bodyUpdate.attach) : bodyUpdate.attach;
            // bodyUpdate.type = _global.getJournalStatus(system).type; //fixed 1 = inventory
            // bodyUpdate.status = 2;
            // bodyUpdate.uuid = body.uuid;
            // delete bodyUpdate.positions;

            // console.log('bodyUpdate>>', bodyUpdate);
            // let { data: response } = await InventoryAdjustApi.update(bodyUpdate, bodyUpdate.id);
            // let newData = response.data;
            // console.log('<<< bodyUpdate', newData);
            // newData.items = JSON.parse(newData.items);
            // newData.attach = JSON.parse(newData.attach);

            //updateTable(newData);
            setTimeout(() => {
                Controller.setLoading(false);
                Controller.setConfirmApprove(false);
            }, 1000)

        } catch (e) {
            console.warn('error send approve', e)
        }


    }

    //console.log('confirmApprove ;:', Controller);
    return (
        <div>
            {/* MOdal Confirm */}
            <div className={`absolute-card shadow ${Controller.confirmApprove ? 'd-block' : 'd-none'}`} >
                <div className="card  shadow">
                    <div className="card-header bg-success">
                        <button type="button" className="close" onClick={() => Controller.setConfirmApprove(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="card-body text-left">
                        <h5 className="card-title">{t('Confirm')}</h5>
                        <div className='d-flex justify-content-between'>
                            <button className='btn btn-secondary btn-sm' onClick={() => Controller.setConfirmApprove(false)}>
                                <FontAwesomeIcon icon={faTimes} /> {t('cancel')}
                            </button>
                            <button className='btn btn-primary btn-sm' onClick={() => btnSendApprove()} data-toggle="modal" data-target={`#${Controller.approve.modalName}`}>
                                <FontAwesomeIcon icon={faCheck} />  {t('Confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SendApprove;
