import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import OrdersApi from '../../api/orders';
import ProductApi from '../../api/products';
import Template from '../../component/common/template';
import MeasureApi from '../../api/measure';
import Table from './table-orders';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk, faPowerOff
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import _global from '../../api/global';
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Orders = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({});
    const [orders, setOrders] = useState(null);
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [detail, setDetail] = useState('');
    const [price, setPrice] = useState(0);
    const [type, setType] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [status, setStatus] = useState('Active');
    const [files, setFiles] = useState("");
    const [ext, setExt] = useState("");
    const [location, setLocation] = useState(0);
    const [unitOfmeasure, setUnitOfmeasure] = useState([]);
    const [dateRange, setDateRange] = useState([null, null, '']);
    const [startDate, endDate] = dateRange;
    const [confirmApprove, setConfirmApprove] = useState(false);
    const [viewData, setViewData] = useState(false);
    const [search, setSearch] = useState('');


    const fetchData = async (start_date, end_date) => {
        try {
            //console.log('start_date::', start_date, 'end_date', end_date)
            let order = await OrdersApi.getOrderList(
                start_date && moment(start_date).format('YYYY-MM-DDT00:00:00.001+07:00'),
                end_date && moment(end_date).format('YYYY-MM-DDT23:59:59.999+07:00'),
                search
            );
            setOrders(order);
            // let unit = await _global.client.get('/v1/unit');
            // setUnitOfmeasure(unit.data);

            let unit = await MeasureApi.search();
            setUnitOfmeasure(unit.data);


            //console.log('unit :::', unit.data);
            //console.log('get ordres::', order)

        } catch (e) {
            console.error('error get Order::', e)
        }

    }

    useEffect(() => {


        localStorage.removeItem('cart-list')

        if (!startDate) {
            let date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 26);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            setDateRange([firstDay, lastDay])
        }

        fetchData(startDate, endDate).catch(console.error);


    }, [dateRange, setOrders, search])


    const deleteProduct = (id) => {

        let data = orders.filter((e) => e.id !== id);
        setOrders(data);
        OrdersApi.remove(id)

        //console.log('new data::', orders)
    }


    const updateSource = async (approve) => {

        let body = {
            status: 2,
            uuid: approve.uuid
        }
        let res = await OrdersApi.updateHeader(body, approve.source_id);
        console.log('<<< update source', res);

    }

    const Controller = ({
        orders,
        confirmApprove,
        setConfirmApprove,
        form,
        setForm,
        viewData,
        setViewData,
        loading,
        setLoading,
        approve: {
            source_des: t('sale cost sheet'),
            source_table: 'orders',
            modalName: 'addModalOrder',
            updateSource: updateSource
        },
        signature: {
            creator: t('Author'),
            system: 'orders',
            department: 'sales',
        }
    })

    return (
        <Template >
            <Table
                Controller={Controller}
                setOrdersList={setOrders}
                data={orders}
                deleteProduct={deleteProduct}
                setName={setName}
                setDetail={setDetail}
                setId={setId}
                setPrice={setPrice}
                setCategoryId={setCategoryId}
                setStatus={setStatus}
                setType={setType}
                setLocation={setLocation}
                OrdersApi={OrdersApi}
                ProductApi={ProductApi}
                DatePicker={DatePicker}
                unitOfmeasure={unitOfmeasure}
                search={search}
                setSearch={setSearch}
                setDateRange={setDateRange}
                startDate={startDate}
                endDate={endDate}
            />
        </Template>
    );
}

export default Orders;
