import React, { useEffect, useState } from 'react';
import Template from '../../component/common/template';
import TemplateSheet from '../../component/common/template-sheet';
import { useTranslation } from 'react-i18next';
import Waiting from '../../component/waiting';
import OrdersApi from '../../api/orders';
import CustomerApi from '../../api/customer';
import ApprovalSetupApi from '../../api/approval_setup';
import _ from 'lodash';
import moment from 'moment/moment';
import UserApi from '../../api/user';
import ApprovalApi from '../../api/approval';
import uuid from 'react-uuid';
import _global from '../../api/global';
import BillingNoteApi from '../../api/finance_billing-note';

const BillingNote = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([{ id: 0, no: 'loading...' }]);
    const [editable, setEditable] = useState(false);
    const [viewData, setViewData] = useState(false);
    const [confirmApprove, setConfirmApprove] = useState(false);
    const [form, setForm] = useState({});
    const [searchOrder, setSearchOrder] = useState({});
    const [approver, setApprover] = useState({});
    const [positions, setPositions] = useState({});
    const [unitOfmeasure, setUnitOfmeasure] = useState([]);

    useEffect(() => {
        getData();
        getApprover()
        getPosition()
    }, []);

    const getPosition = async () => {
        let res = await UserApi.getUserPosition('store');
        //console.log('get pisition res ==> ', res);
        setPositions(res.data);
    }

    const getApprover = async () => {
        let { data } = await ApprovalSetupApi.search('journal');
        //console.log('approver ******', data);
        setApprover(data)
    }

    const updateTable = (row) => {
        // update table
        let tableList = [];
        data.forEach(el => {
            if (parseInt(el.id) === parseInt(row.id)) {
                tableList.push(row)
            } else {
                tableList.push(el);
            }

        })
        let index = _.findIndex(data, { id: row.id });
        // Replace item at index using native splice
        tableList.splice(index, 1, { ...row });
        //console.log('tableList::', tableList);
        setData(tableList);
    }

    const getData = async () => {
        setLoading(true);


        let unit = await _global.client.get('/v1/unit');

        setUnitOfmeasure(unit.data);


        setTimeout(async () => {
            let date = new Date();
            var from = new Date(`${date.getFullYear()}-01-01`).toISOString();
            var to = new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString();
 
            let { data } = await BillingNoteApi.search(1, 2, from, to);
            setData(data);
            setLoading(false);
        }, 300);
    }

    // save button
    const create = async () => {
        let body = _.cloneDeep(form);
        body.items = JSON.stringify(body.items);
        body.attach = body.attach ? JSON.stringify(body.attach) : null;
        body.options = body.options ? JSON.stringify(body.options) : null;
        delete body.positions;

        setLoading(true);
        let newData = [];
        if (body.id) {
            //update
            let sum_total = await _global.Totals(form.items, form.discount, form.withholdingTax, form);
            body.status = 1;
            body.uuid = uuid();
            body.amount = sum_total.totals;
            //console.log('update body::', body);
            let { data: res } = await BillingNoteApi.update(body, body.id);
            console.log('res update::', res);
            newData = res.data;
            newData.items = JSON.parse(newData.items);
            newData.attach = JSON.parse(newData.attach);
            newData.options = JSON.parse(newData.options);
            updateTable(newData)
        } else {
            //create
            console.log('create body::', body);
            let { data: res } = await BillingNoteApi.create(body);
            console.log('res create::', res);
            let newRow = res.data;
            newRow.items = JSON.parse(newRow.items) || [];
            newRow.attach = JSON.parse(newRow.attach) || [];
            newRow.options = JSON.parse(newRow.options) || [];
            newData = data.concat(newRow);
            //console.log('after push::', newData);

            // update table
            setData(newData);
            //update form
            setForm({ ...form, ...{ id: res.data.id, positions: positions } })
        }

        // close edit
        setTimeout(() => {
            setLoading(false);
            setViewData(true);
            setTimeout(() => {
                //setEditable(false);
            }, 1000)
        }, 500)
    }

    const remove = async (row) => {
        try {

            //console.log('delete row::', row);
            let { data: res } = await BillingNoteApi.remove(row.id);
            //console.log('res delete::', res);

            let newRaw = data.filter(el => el.id !== row.id)
            //console.log('new raw data::', newRaw);
            setData(newRaw);
        } catch (e) {
            console.error('e', e);
        }

    }

    const getSource = async (value) => {

        setForm({
            ...form,
            source_no: value
        })
        let res = await OrdersApi.searchOrder(value)

        let newData = [];
        for(let source of res){
            let customer = await CustomerApi.searchByCid(source.cust_cid);
            console.log('customer::', customer);
            newData.push({
                ...source,
                cust_addr: customer[0].address + ' ' + customer[0].address2,
                cust_cid: customer[0].tax_id,
                items: source.items ? JSON.parse(source.items) : [],
            })
        }
       
        console.log('<< res get source', newData);
        setSearchOrder(newData)

    }

    const clickSelectSource = async (source) => {
        console.warn('click source')
        // if (source.status !== 4) {
        //     alert(t('status must be Approved'));
        // } else {
            let newForm = {
                ...form,
                create_date: source.order_date,
                no: form.no || '',
                source_no: source.document_no,
                cust_name: source.cust_name,
                cust_address: source.cust_addr,
                cust_tax_id: source.cust_cid,
                items: source.items,
                positions: positions,
                options: JSON.stringify({
                    start_date: source.start_date || new Date().toISOString(),
                    end_date: source.end_date || new Date().toISOString()
                })
            }
            console.warn('new form ++>>', newForm)
            setForm(newForm);

            //clear for hidden search result
            setSearchOrder([]);
        //}

    }

    const sendApprove = async () => {
        try {
            setLoading(true);
            console.log('form send approve::', form);
            let body = {
                source_des: 'ใบวางบิล',
                source_table: "billing_note",
                source_id: form.id,
                source_no: form.no,
                approve_when: "string",
                gps: "0,0",
                payload: JSON.stringify(form),
                uuid: uuid(),
                type: 2
            }
            console.log('body::', body)
            let res = await ApprovalApi.create(body);
            console.log('res approval>>', res);

            /**
             * clear status pending
             */
            let bodyUpdate = _.cloneDeep(form);
            bodyUpdate.items = JSON.stringify(bodyUpdate.items);
            bodyUpdate.attach = typeof bodyUpdate.attach === 'object' ? JSON.stringify(bodyUpdate.attach) : bodyUpdate.attach;
            bodyUpdate.status = 2;
            bodyUpdate.uuid = body.uuid;
            delete bodyUpdate.positions;

            let { data: response } = await BillingNoteApi.update(bodyUpdate, bodyUpdate.id);
            let newData = response.data;
            newData.items = newData.items?.length > 0 ? JSON.parse(newData.items) : [];
            newData.attach = JSON.parse(newData.attach);
            updateTable(newData);

            setTimeout(() => {
                setEditable(false);
                setLoading(false);
            }, 1000)

        } catch (e) {
            console.warn('error send approve', e)
        }


    }

    const modify = () => { //enable
        setForm({}); // clear First
        setViewData(false)
        setEditable(true)
        setForm(form)
    }

    const controller = ({
        create,
        remove,
        loading,
        viewData,
        setViewData,
        editable,
        setEditable,
        getSource,
        setForm,
        confirmApprove,
        setConfirmApprove,
        sendApprove,
        form,
        setApprover,
        approver,
        positions,
        unitOfmeasure,
        setUnitOfmeasure,
        default: {
            create_date: new Date(),
            no: 'IN' + Date.now(),
            cust_address: '',
            items: [],
            positions: positions
        },
        setSearchOrder,
        searchOrder,
        clickSelectSource,
        modify,
        approve: {
            source_des: 'ใบวางบิล',
            source_table: 'billing_note',
            modalName: 'addModalBillingNote',
            updateSource: () => { }
        },
        signature: {
            creator: t('billing note'),
            system: 'billing_note',
            department: 'finance',
        },
        disableApprove: true
    })
    console.log('data', data, 'form>', form)
    return (
        <Template hideToTop={true} hideFooter={true}>
            <TemplateSheet controller={controller}>
                <Waiting loading={loading} />
                <BillingNoteApi.TableList  data={data} controller={controller} />
            </TemplateSheet>
        </Template>
    );

}

export default BillingNote;
