import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchItem from './order-searchItem';

import {
    faMinusCircle,
    faSquarePlus,
    faSearch,
    faCancel
} from '@fortawesome/free-solid-svg-icons';

import { setDefaultLocale } from "react-datepicker";
import global from '../../api/global';
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';

const OrderEditable = ({
    dataModal,
    enableEdit,
    changeObject,
    handleEnter,
    setNewLine,
    removeNewLine,
    newLine,
    addrow,
    setAddrow,
    showSearchItem,
    setShowSearchItem,
    handleClose,
    handleShow,
    show,
    setShow,
    searchItem,
    searchResult,
    pickItem,
    searchByName,
    unitOfmeasure
}) => {
    const { t } = useTranslation();


    const NumberFormat = global.NumberFormat;
    const fetchData = async () => {
        try {

        } catch (e) {

        }
    }

    useEffect(() => {
        setDefaultLocale('th');
        fetchData().catch(console.error);
    }, [dataModal])


    const addRow = (text) => {

        pickItem(null, {
            id: text === 'outsource' ? 1 : 0,
            date_amount: 1,
            discount: "0.00",
            end_date: null,
            img: "",
            qty: 1,
            item: 0,
            name: t(text),
            description: "",
            orders: dataModal.header.id,
            cost: 0,
            price: 0,
            setting: "",
            unit: JSON.stringify({ [t('unit')]: t('unit') })
        });
    }



    return (
        <div className='position-relative '>
            {
                enableEdit === 'd-none' &&
                <SearchItem
                    showSearchItem={showSearchItem}
                    setShowSearchItem={setShowSearchItem}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    show={show}
                    setShow={setShow}
                    searchItem={searchItem}
                    searchResult={searchResult}
                    pickItem={pickItem}
                    searchByName={searchByName}
                />
            }
            <div className='row'>
                <div className="col"  >
                    <table className="table table-bordered mt-2 "  >
                        <thead>
                            <tr>
                                <th>{t('#')}</th>
                                <th style={{ minWidth: 120, width: '30%' }}>{t('Product name')}</th>
                                <th className='text-right' style={{ minWidth: 70 }}>{t('Qty')}</th>
                                <th className='text-left' style={{ minWidth: 90 }}>{t('unit')}</th>
                                <th className='text-right' style={{ minWidth: 95 }}>{t('date rent')}</th>
                                <th className='text-left' style={{ minWidth: 100 }}>{t('time unit')}</th>
                                <th className='text-right' style={{ minWidth: 100 }}>{t('cost')}</th>
                                <th className='text-right' style={{ minWidth: 120 }}>{t('price')} {t('sale/rent')}</th>
                                <th className='text-right' style={{ minWidth: 150 }} colSpan="3">{t('Total Price')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                enableEdit === 'd-none' && dataModal.line?.length > 0 && dataModal.line?.map((line, i) =>
                                    <tr key={line.id + i} id={line.uuid}>
                                        <td >{i + 1}</td>
                                        <td>
                                            <input
                                                type="text"
                                                className={`form-control`}
                                                readOnly={line.item > 1 ? true : false}
                                                name={`name`}
                                                defaultValue={line.name || ''}
                                                onChange={() => null}
                                                onBlur={(el) => changeObject(el)}
                                                onKeyDown={(el) => handleEnter(el, "qty")}
                                            />
                                        </td>
                                        <td className='text-right'>
                                            <input
                                                type="text"
                                                className="form-control text-right"
                                                name={`qty`}
                                                defaultValue={NumberFormat(line.qty || 0, 0)}
                                                onFocus={(event) => event.target.select()}
                                                onBlur={(el) => changeObject(el)}
                                                onKeyDown={(el) => handleEnter(el, "date_amount")}
                                            />
                                        </td>
                                        <td className='text-left'>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control text-left"
                                                    name={`units`}
                                                    value={line.unit || ''}
                                                    readOnly={true}
                                                />
                                                <div className="input-group-append">
                                                    <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="sr-only">Toggle Dropdown</span>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        {
                                                            unitOfmeasure.length && unitOfmeasure.map((element, index) => (
                                                                <button
                                                                    className="dropdown-item"
                                                                    name="unit"
                                                                    key={index}
                                                                    value={element.name}
                                                                    id={element.id}
                                                                    onClick={(e) => changeObject(e)}
                                                                >
                                                                    {element.name}
                                                                </button>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-right'>
                                            <input
                                                type="text"
                                                className="form-control text-right"
                                                autoComplete='off'
                                                name={`date_amount`}
                                                defaultValue={NumberFormat(line.date_amount || 0, 0)}
                                                onFocus={(event) => event.target.select()}
                                                onBlur={(el) => changeObject(el)}
                                                onKeyDown={(el) => handleEnter(el, line.item > 1 ? "cost" : "price")}
                                            />

                                        </td>
                                        <td className='text-left'>
                                            {line.item === 1 ?
                                                <div>
                                                    <input
                                                        type={'text'}
                                                        value={line.time_unit}
                                                        name="time_unit"
                                                        className="form-control"
                                                        onChange={(el) => changeObject(el)}
                                                    />
                                                </div>
                                                :
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control text-left"
                                                        autoComplete='off'
                                                        name={`time_unit`}
                                                        value={line.time_unit || ''}
                                                        readOnly={true}
                                                    />
                                                    <div className="input-group-append">
                                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="sr-only">Toggle Dropdown</span>
                                                        </button>
                                                        <div className="dropdown-menu">
                                                            {
                                                                line.prices && Object.keys(line.prices).map((element, index) => (
                                                                    <button
                                                                        className="dropdown-item"
                                                                        name="time_unit"
                                                                        key={index}
                                                                        value={element}
                                                                        onClick={(e) => changeObject(e, line.prices[element])}
                                                                    >
                                                                        {element}
                                                                    </button>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                        <td className='text-right'>

                                            <input
                                                type="text"
                                                className={`form-control text-right  }`}
                                                name={`cost`}
                                                defaultValue={parseFloat(line.cost || 0)}
                                                onFocus={(event) => event.target.select()}
                                                onBlur={(el) => changeObject(el)}
                                                onKeyDown={(el) => handleEnter(el, "price")}
                                            />

                                        </td>
                                        <td className='text-right'>
                                            {
                                                line.item > 1 ? <input
                                                    type="text"
                                                    className="form-control text-right"
                                                    name={`price`}
                                                    value={parseFloat(line.price) || 0}
                                                    readOnly={true}
                                                    onKeyDown={(el) => handleEnter(el, "total")}
                                                />
                                                    :
                                                    <input
                                                        type="text"
                                                        className="form-control text-right"
                                                        name={`price`}
                                                        defaultValue={parseFloat(line.price) || 0}
                                                        onFocus={(event) => event.target.select()}
                                                        onBlur={(el) => changeObject(el)}
                                                        // onKeyDown={(el) => handleEnter(el, "total")}
                                                        autoComplete='off'
                                                    />
                                            }

                                        </td>
                                        <td className='text-right position-relative'>
                                            <input
                                                type="text"
                                                className="form-control text-right"
                                                name={`total`}
                                                value={NumberFormat(((line.qty * line.date_amount * line.price) - (line.qty * line.date_amount * line.cost)) || 0)}
                                                disabled={true}
                                            />

                                        </td>
                                        <td>
                                            <div
                                                className={`position-absoluteX pointer cursor-pointer`}
                                                style={{ top: 12, right: -9 }}
                                                onClick={el => removeNewLine(el, line)}
                                                title={t('delete')}
                                            >
                                                <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }

                            {
                                enableEdit === 'd-block' && dataModal.line?.length > 0 && dataModal.line?.map((line, i) =>

                                    <tr key={line.id + i} id={line.uuid}>
                                        <td >{i + 1}</td>
                                        <td>
                                            {line.name}
                                        </td>
                                        <td className='text-right'>
                                            {NumberFormat(line.qty || 0, 0)}
                                        </td>
                                        <td className='text-left'>
                                            {line.unit || ''}
                                        </td>
                                        <td className='text-right'>
                                            {NumberFormat(line.date_amount || 0, 0)}
                                        </td>
                                        <td className='text-left'>
                                            {line.time_unit || ''}
                                        </td>
                                        <td className='text-right'>
                                            {NumberFormat(line.cost || 0)}
                                        </td>
                                        <td className='text-right'>
                                            {NumberFormat(line.price || 0)}
                                        </td>
                                        <td className='text-right position-relative' colSpan="2">
                                            {/* {NumberFormat(((line.date_amount * line.price) - (line.date_amount * line.cost)) || 0)} */}
                                            {NumberFormat(((line.qty * line.date_amount * line.price) - (line.qty * line.date_amount * line.cost)) || 0)}
                                        </td>
                                    </tr>
                                )
                            }

                            {
                                addrow === true && enableEdit === 'd-none' &&
                                <tr id={0} style={{ backgroundColor: "rgb(204 204 204 / 11%)" }}>
                                    <td className='text-success'> </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control "
                                            name="name"
                                            value={newLine.name || ''}
                                            onChange={(el) => setNewLine({ ...newLine, name: el.target.value })}
                                            onKeyDown={(el) => handleEnter(el, "date_amount")}
                                            onFocus={(event) => event.target.select()}
                                            placeholder={t('text')}
                                            aria-label={t('text')}
                                            aria-describedby="basic-addon2"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control text-right"
                                            name="date_amount"
                                            value={newLine.qty || 0}
                                            onChange={(el) => setNewLine({ ...newLine, qty: el.target.value })}
                                            onKeyDown={(el) => handleEnter(el, "unit")}
                                            onFocus={(event) => event.target.select()}
                                        />
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="unit"
                                                value={newLine.unit || t('unit')}
                                                onChange={(el) => setNewLine({ ...newLine, unit: el.target.value })}
                                                onKeyDown={(el) => handleEnter(el, "cost")}
                                                onFocus={(event) => event.target.select()}
                                            />
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="sr-only">Toggle Dropdown</span>
                                                </button>
                                                <div className="dropdown-menu">
                                                    {
                                                        newLine.units && Object.keys(newLine.units).map((element, index) => (
                                                            <button className="dropdown-item" key={index} >{element}</button>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control text-right"
                                            name="cost"
                                            value={newLine.cost || 0}
                                            onChange={(el) => setNewLine({ ...newLine, cost: el.target.value })}
                                            onKeyDown={(el) => handleEnter(el, "price")}
                                            onFocus={(event) => event.target.select()}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control text-right"
                                            name="price"
                                            value={newLine.price || 0}
                                            onChange={(el) => setNewLine({ ...newLine, price: el.target.value })}
                                            onKeyDown={(el) => handleEnter(el, "total")}
                                            onFocus={(event) => event.target.select()}
                                            title={t('Press Enter on the keyboard for insert to new line')}
                                        />
                                    </td>
                                    <td></td>
                                    <td className='position-relative text-right ' >
                                        <div className='cursor-pointer font-11' onClick={() => setAddrow(false)} title={t('cancel')}>
                                            <FontAwesomeIcon icon={faCancel} className="mr-1" />
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {!addrow === true && enableEdit === 'd-none' &&
                < div className="row">
                    <div className="col my-3 text-right">
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-warning btn-sm" onClick={() => addRow('text')}>
                                <FontAwesomeIcon icon={faSquarePlus} style={{ fontSize: 15 }} className="mr-1" />
                                {t('expenses')}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" onClick={() => addRow('outsource')}>
                                <FontAwesomeIcon icon={faSquarePlus} style={{ fontSize: 15 }} className="mr-1" />
                                {t('product outside')}
                            </button>
                            <Button variant="primary" className="btn btn-info btn-sm" onClick={handleShow}>
                                <FontAwesomeIcon icon={faSearch} style={{ fontSize: 15 }} className="mr-1" />
                                {t('search product')}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div >
    );


}

export default OrderEditable;
