import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


function BaseUnit({ controller, id = null }) {
    const { t } = useTranslation();
    let form = controller.form;
    useEffect(() => {

    }, [])

    return (
        <div className={``}>
            <div className="input-group" id={id}>
                <input
                    type="text"
                    className="form-control text-left"
                    name={`unit`}
                    value={typeof form.unit === 'string' && Object.keys(JSON.parse(form.unit)) || ''}
                    readOnly={true}
                />
                <div className="input-group-append">
                    <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu">
                        {
                            controller.measuresList?.length && controller.measuresList?.map((element, index) => (
                                <button
                                    className="dropdown-item"
                                    name="unit"
                                    key={index}
                                    value={element.name}
                                    onClick={(e) => controller.setForm({
                                        ...form,
                                        base_unit: element.id,
                                        unit: JSON.stringify({[element.name]: element.name})
                                    })}
                                >
                                    {element.name}
                                </button>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BaseUnit;

