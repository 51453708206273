import _global from './global';
import CryptoJS from 'crypto-js';
import uuid from 'react-uuid';
const axios = require('axios').default;
const config = require(`../config/${process.env.NODE_ENV}`).default;
const package_json = require('../../package.json');
console.log('version::', package_json.version);

const client = axios.create({
    baseURL: config.api
});

client.interceptors.request.use(
    config => {
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
            let token = _global.getToken();

            if (token.comp < 1 || token.id < 1) {
                console.error('session timeout')
                localStorage.removeItem('token');
                window.location.href = '/'
            }
        } else {
            localStorage.removeItem('token')
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use((response) => {
    let version = package_json.version;
    if (localStorage.getItem('version') !== version) {
        localStorage.setItem('version', version);
        localStorage.removeItem('token');
        localStorage.removeItem('products');
        localStorage.removeItem('category');
        window.location.href = '/'
    }
    return response;
}, (error) => {

    if (error.response.status === 401) {
        localStorage.removeItem('token')
    } else if (error.response.status === 0) {
        console.error('GATEWAY ERROR');
        throw 'gate error';
    } else {
        console.error('error.response.status::', error.response);
    }
    return Promise.reject(error.message);
});

const pef1 = {

    encrypt: (plainText, secret) => {

        try {
            // let iv = crypto.randomBytes(12);
            // let salt = crypto.randomBytes(16);
            // let key = crypto.createHmac('sha256', secret).update(salt).digest();
            // let cipher = crypto.createCipheriv('aes-256-gcm', key, iv);
            // let cipherText = cipher.update(plainText, 'utf8', 'base64') + cipher.final('base64');
            // let tag = cipher.getAuthTag();
            // let msg = {
            //     f: 'pef1',
            //     s: salt.toString('base64'),
            //     i: iv.toString('base64'),
            //     t: tag.toString('base64'),
            //     d: cipherText
            // };
            // return JSON.stringify(msg);

            var text = plainText;
            var key = CryptoJS.enc.Base64.parse(secret);
            var iv = CryptoJS.enc.Base64.parse("                ");
            var encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });
            console.log(encrypted.toString());

            // let encrypted = CryptoJS.AES.encrypt(plainText, key, { iv })
            let cipherText = encrypted.ciphertext;
            let tag = encrypted.iv;
            //console.log(encrypted.iv + ':' + encrypted.ciphertext);


            let msg = {
                f: 'pef1',
                s: key,
                i: iv,
                t: tag,
                d: encrypted.toString()
            };
            return msg;
        } catch (e) {

        }


    },

    decrypt: (cipherText, secretKey) => {
        try {
            let encrypted = cipherText;
            console.log('typeof', cipherText)

            var decrypted = CryptoJS.AES.decrypt(encrypted.d, secretKey, { iv: encrypted.i });
            console.log('decrypt::', decrypted);
            console.log(decrypted.toString(CryptoJS.enc.Utf8));
            return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (e) {
            console.error('error decrypt:', e)
            return null;
        }
    }
}

export default {
    client,
    pef1
};