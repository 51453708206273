import React, { useEffect, useState } from 'react';
import ReceiveJournalApi from '../../../api/journal_receive';
import { useTranslation } from 'react-i18next';
import Waiting from '../../../component/waiting';
import ApprovalSetupApi from '../../../api/approval_setup';
import _ from 'lodash';
import moment from 'moment/moment';
import UserApi from '../../../api/user';
import ApprovalApi from '../../../api/approval';
import uuid from 'react-uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import Signature from '../../../component/common/signature';
import FileUpload from '../../../component/common/fileUpload';

const ReturnReceivePrintReturnOrder = ({ controller, props }) => {
    const { t } = useTranslation();

    let form = controller.form;
    useEffect(() => {

    });

    console.log('form::', form);
    return (
        <div className={`mb-5 only-print`} style={{
            border: '0px solid #ccc',
            padding: 0,
            background: "#fff",
            position: 'relative'
        }}>
            <table className='table table-bordered' >
                <thead>
                    <tr>
                        <th className='text-center' colSpan={'6'}>
                            <h5>{t('ใบส่งคืนสินค้า')} <sub className='font-9 disabled-print'>{`${form?.id}`}</sub></h5>
                            <div className='font-13'> </div>
                        </th>
                        <th colSpan={'2'} width="20%" className='text-center'>
                            <img src="/assets/images/thammasak-q.png" className='w-100' style={{ maxWidth: 120 }} />
                        </th>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td colSpan={'5'} rowSpan={'3'}>
                            <div className='mb-5 d-flex'>
                                <span style={{ minWidth: 50 }}>{t('customer name')}</span> :
                                <span className='text-primary w-100 ml-2' style={{ marginTop: -6 }}>
                                    <textarea
                                        className="form-control d-print-noline font-12"
                                        id="header"
                                        rows="3"
                                        readOnly={true}
                                        defaultValue={form.header || ''}
                                    />
                                </span>
                            </div>
                            <div className='d-flex'>
                                <span style={{ minWidth: 50 }}>{t('Project')}</span> :
                                <span className='text-primary w-100 ml-2' style={{ marginTop: -6 }}>
                                    <input
                                        name="detail"
                                        type={'text'}
                                        className="form-control form-control-sm d-print-noline"
                                        readOnly={true}
                                        defaultValue={form.detail || ''}
                                    />
                                </span>
                            </div>
                        </td>
                        <td width="10%" style={{ minWidth: 120 }}>{t('document no')}</td>
                        <td colSpan={'2'}>
                            <input
                                name="no"
                                type={'text'}
                                className="form-control form-control-sm d-print-noline"
                                readOnly={true}
                                defaultValue={form.no || ''}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td>{t('document source')}</td>
                        <td colSpan={'2'}>
                            <input
                                name="source_no"
                                type={'text'}
                                className="form-control form-control-sm d-print-noline"
                                placeholder={t('input journal number.')}
                                readOnly={true}
                                defaultValue={form.source_no || ''}
                            />
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td>{t('date request')}</td>
                        <td colSpan={'2'}>
                            {form.create_date}
                        </td>
                    </tr>

                    <tr className='font-12 font-weight-bold'>
                        <td width="2%" rowSpan={2} className='text-center align-middle'>{t('No.')}</td>
                        <td width="25%" rowSpan={2} className='text-center align-middle'>{t('รายการเช่า')}</td>
                        <td colSpan={2} className='text-center'>{t('วัสดุที่ลูกค้าเช่า (ขารับ)')}</td>
                        <td colSpan={4} className='text-center'>{t('จานวนวัสดุเช่าและสภาพการใช้งาน (ส่งคืน)')}</td>
                    </tr>
                    <tr className='font-12 font-weight-bold'>
                        <td className='text-center'>จำนวน</td>
                        <td className='text-center'>หน่วย</td>

                        <td className='text-center' style={{ width: 90 }} >จำนวน</td>
                        <td className='text-center' style={{ width: 90 }} >หน่วย</td>
                        <td className='text-center' style={{ minWidth: 80 }} >สภาพปกติ</td>
                        <td className='text-center' style={{ minWidth: 170 }} >ชำรุด/เสียหาย(ระบุจำนวน)</td>
                    </tr>
                </thead>

                <tbody className='font-12'>
                    {
                        form.items?.length > 0 && form.items.map((item, index) => (
                            <tr key={index + 1} id={item.id}>
                                <td>{index + 1}</td>
                                <td>{item?.name}</td>
                                <td className='text-right' >{Number(item?.qty)}</td>
                                <td className='text-center'>{item?.unit}</td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                            </tr>
                        ))
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={'9'} className="font-11">
                            <div> **พนักงานรับสินค้าจะต้องตรวจนับสินค้าเช่าพร้อมเช็ค</div>
                            <div>สภาพการใช้งาน หากพบสินค้าเช่าชารุดหรือสูญหายให้แจ้ง</div>
                            <div>ลูกค้า เพื่อดาเนินการเรียกเก็บค่าเสียหายแก่ลูกค้า</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={'9'} className="font-12">
                            <div className='border rounded d-flex justify-content-between font-12 py-2' style={{ paddingLeft: 50, paddingRight: 50 }}>

                                <div className='text-center'>
                                    <div>ผู้ออกเอกสาร :</div>

                                    <div className='mt-5'>......................................</div>
                                    <div>วันที่......../......../................</div>
                                </div>

                                <div className='text-center'>
                                    <div>พนักงานรับสินค้า :</div>

                                    <div className='mt-5'>......................................</div>
                                    <div>วันที่......../......../................</div>
                                </div>

                                <div className='text-center'>
                                    <div>คลังรับสินค้า :</div>

                                    <div className='mt-5'>......................................</div>
                                    <div>วันที่......../......../................</div>
                                </div>

                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );

}

export default ReturnReceivePrintReturnOrder;