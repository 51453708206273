import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk, faPowerOff
} from '@fortawesome/free-solid-svg-icons';

const ModalNew = ({ children, submitForm, ModalName, closeModal, mode = 'modal-fullscreen', bg = 'bg-primary' }) => {

    const { t } = useTranslation();


    return (
        <div className={`modal fade ${mode}`} id="addModalNew" data-backdrop="static" data-keyboard="false" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className={`modal-dialog ${mode}`} role="document">
                <div className="modal-content">
                    <div className={`modal-header ${bg} text-white`}>
                        <h5 className="modal-title modal-add-title">{ModalName}</h5>
                        <button className="close" type="button" id={`close-addModalNew`} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body modalNew-add-detail">
                        {children}
                    </div>
                    <div className={`modal-footer ${bg}`}>
                        <button className="btn btn-success btn-sm" type="button" onClick={submitForm}><FontAwesomeIcon icon={faFloppyDisk} /> {t('save')}</button>
                        <button className="btn btn-secondary btn-sm" type="button" id="close-modal-product" data-dismiss="modal" ><FontAwesomeIcon icon={faPowerOff} /> {t('close')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalNew;
