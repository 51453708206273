import React from 'react';
import { useTranslation } from 'react-i18next';
import Template from '../component/common/template';

const Configurations = () => {
    const { t } = useTranslation();
    
    return (
        <Template>
             {t('Configurations')}
 
        </Template>
    );
}

export default Configurations;
