import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import OrdersApi from '../api/orders';
import MeasureApi from '../api/measure';
import Template from '../component/common/template';
import MeasureTable from '../component/measure-table';
import ModalNew from '../component/modalNew';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk, faPowerOff
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import global from '../api/global';
import Waiting from '../component/waiting';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';

const Measure = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                let response = await MeasureApi.getData();
                setData(response);
            } catch (e) {
                console.error('error get unit::', e)
            }

        }
        fetchData().catch(console.error);


    }, [])

    const submitForm = async (e) => {
        e.preventDefault();
        console.log('submit data::', formData, isNaN(formData.id));
        try {
            setLoading(true);
            if (!isNaN(formData.id)) {
                //update to db
                //console.log('do update')
                let body = _.omit(formData, 'id');
                let res = await MeasureApi.updateData(body, formData.id);
                //console.log('res updateData::', res);
                if (res.data.status === 2) {

                    let index = _.findIndex(data, { id: formData.id });
                    // Replace item at index using native splice
                    data.splice(index, 1, res.data.data);

                    setData(data);

                    setTimeout(() => {
                        setLoading(false);
                        $('body').find('button.close').click();
                    }, 500)
                    return res.data;
                }

            } else {
                //add to db
                let res = await MeasureApi.newData({
                    code: formData.code,
                    name: formData.name
                });

                let newUnit = _.clone(data);
                newUnit.push({
                    code: formData.code,
                    name: formData.name
                })
                //console.log('newUnit::', newUnit);
                setData(newUnit);

                setTimeout(() => {
                    setLoading(false);
                    //$('body').find('button.close').click();
                }, 500)

                return res;
            }
        } catch (e) {
            console.error('error::', e);
        }
    };

    // const deleteProduct = (id) => {

    //     let data = products.filter((e) => e.id !== id);
    //     setProducts(data);
    //     Product.remove(id)

    //     console.log('new data::', products)
    // }

    const closeModal = () => {
        /**
         * Clear data after close modal
         */


        document.getElementById("close-addModalNew").click();
    }


    return (
        <Template >
            <Waiting loading={loading} />
            <ModalNew
                submitForm={submitForm}
                ModalName={t('Unit Of Measure')}
                closeModal={closeModal}
                mode={'modal-success'}
                bg={'bg-info'}
            >
                <MeasureApi.FormAddData
                    data={data}
                    setData={setData}
                    setLoading={setLoading}
                    formData={formData}
                    setFormData={setFormData}
                />
            </ModalNew>
            <MeasureTable data={data} MeasureApi={MeasureApi} setFormData={setFormData} setData={setData} />
        </Template>
    );
}

export default Measure;
