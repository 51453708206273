import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import _global from './global';

const search = async (value) => {
    try {
        let response = await _global.client.get(`/v2/company`, {
            params:
            {
                search: value
            }
        });

        let list = [];
        for (let row of response.data) {
            list.push({
                ...row,
                data: row.data ? JSON.parse(row.data) : []
            })
        }
        //console.log('response::', list)
        return { data: list };
    } catch (error) {
        console.error(error);
    }
}


const get = async (id) => {
    try {
        const response = await _global.client.get(`/v1/company/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const update = async (body, id) => {
    try {
        return await _global.client.patch(`/v1/company/${id}`, body);
    } catch (e) {
        console.error('error::', e);
    }
}


const CompanyApi = {
    get,
    search,
    update
};

export default CompanyApi;